var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("removed attachment "),
    _c("strong", [_vm._v("#" + _vm._s(_vm.log.context.attachmentId))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }