<template>
    <div class="task-steps__task__container">
        <div class="task-steps__task"
             :class="{completed:step.completed, 'not-achieved': isCompletedButNotAchieved}"
             :style="stepStyle">

            <goal-task-complete-dropdown
                    v-if="allowSetAssistanceOptions"
                    :provided-assistance="step.provided_assistance"
                    :allow-incomplete="step.completed"
                    :available-completion-options="availableCompletionOptions"
                    @updated="onCompletionOptionsUpdated"
            >
                <i
                        role="checkbox"
                        :aria-checked="step.completed?'true':'false'"
                        tabindex="0"
                        :aria-label="step.name"
                        class="far fa-check-square task-steps__task__checkbox vertical-middle"
                        :class="{pointer:completable}"
                        :style="stepStyle"
                >
                </i>
            </goal-task-complete-dropdown>
            <i v-else
               role="checkbox"
               :aria-checked="step.completed?'true':'false'"
               tabindex="0"
               :aria-label="step.name"
               @click="onCompleteCheckboxClicked"
               @keydown.enter="onCompleteCheckboxClicked"
               class="far fa-check-square task-steps__task__checkbox vertical-middle"
               :class="{pointer:completable}">
            </i>

            <span class="task-steps__task__name vertical-middle">{{
                    index ? (index + '. ') : ''
                }}{{ step.name | cutName(450) }}</span>
            <span @click.prevent.stop="editStep" v-if="allowEdit"
                  class="pointer vertical-middle task-steps__task__remove-label color-black">
                <i class="far fa-pencil pointer"></i>
            </span>
            <task-main-info-completion-data
                    :completable-instance="step"
                    v-if="step.completed"
            ></task-main-info-completion-data>
            <goal-task-complete-dropdown
                    ref="complete-dropdown"
            ></goal-task-complete-dropdown>
        </div>
        <div class="" v-if="step.description">
            <i>{{ stepDescription }}</i> <i class="pointer" @click="showFullDescription"
                                            v-if="!fullDescriptionRevealed&&descriptionIsTooLarge"><strong>Show
            more</strong></i>
        </div>
    </div>

</template>

<script>
    import TaskMainInfoCompletionData from './TaskMainInfoCompletionData.vue';
    import GoalTaskCompleteDropdown
        from '../GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskCompleteDropdown.vue';
    import TaskStepMixin from '../GoalDetailPage/goal_tasks/components/GoalTask/TaskStepMixin';

    const MAX_VISIBLE_DESCRIPTION_LENGTH = 100;

    export default {
        components: {
            GoalTaskCompleteDropdown,
            TaskMainInfoCompletionData
        },
        mixins: [TaskStepMixin],
        props: {
            availableCompletionOptions: Array,
            allowEdit: {
                type: Boolean,
                default: false,
            },
            index: {
                type: Number,
                required: false
            }
        },
        data() {
            return {
                fullDescriptionRevealed: false,
            };
        },
        computed: {
            descriptionIsTooLarge() {
                if (!this.step.description) {
                    return false;
                }
                return this.step.description.length > MAX_VISIBLE_DESCRIPTION_LENGTH;
            },
            stepDescription() {
                if (this.step.description) {
                    if (this.fullDescriptionRevealed) {
                        return this.step.description;
                    } else {
                        return this.$options.filters.cutName(this.step.description, MAX_VISIBLE_DESCRIPTION_LENGTH);
                    }
                }
            }
        },
        methods: {
            showFullDescription() {
                this.fullDescriptionRevealed = true;
            },
            editStep() {
                this.$emit('editStep', this.step);
            }
        },
        name: 'TaskMainInfoTaskStep'
    };
</script>

<style scoped lang="scss">
  @import "../../styles/const";

  .task-steps {
    &__task {
      &__container {
        border-bottom: 1px solid $lightgray;
      }

      $task-selector: &;

      &.completed {
        #{$task-selector}__name, #{$task-selector}__checkbox {
          text-decoration: line-through;
        }

        &.not-achieved {
          #{$task-selector}__name, #{$task-selector}__checkbox {
            text-decoration: line-through;
          }
        }
      }

      &__name {

      }

      &__remove-label {
      }

      font-size: 19px;
      color: $tag-gray;

      &__checkbox {
        display: inline-block;
        font-size: 21px;
      }


    }

  }
</style>
