var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goal-task-child", style: [_vm.taskBackgroundColor] },
    [
      _vm.editMode && _vm.isEditable && _vm.allowDelete
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.deleteTaskLabel,
                  expression: "deleteTaskLabel",
                },
              ],
              staticClass: "goal-task-child__button-delete",
              on: { click: _vm.deleteTask },
            },
            [_c("i", { staticClass: "fa fa-trash-o" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "goal-task-child__main-part" }, [
        _c(
          "div",
          {
            staticClass: "goal-task-child__avatar-and-checkbox",
            class: {
              "no-avatar":
                _vm.task.uses_default_avatar && !_vm.task.uses_generated_avatar,
              "with-checkbox":
                _vm.task.has_steps ||
                _vm.isCompletedMarkEditable(_vm.task) ||
                (_vm.editMode && _vm.isEditable),
            },
          },
          [
            _c("div", { staticClass: "inline-block vertical-top" }, [
              _c(
                "div",
                {
                  staticClass:
                    "goal-task-child__avatar-and-checkbox__sync-mark__container",
                },
                [
                  _vm.editMode && _vm.isEditable
                    ? _c("i", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Apply parent task's changes.",
                            expression: "'Apply parent task\\'s changes.'",
                          },
                        ],
                        staticClass:
                          "fa fa-sync goal-task-child__avatar-and-checkbox__sync-mark",
                        style: _vm.syncMarkStyle,
                        on: { click: _vm.toggleSync },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.task.has_steps || _vm.isCompletedMarkEditable(_vm.task)
                ? _c(
                    "div",
                    {
                      staticClass:
                        "goal-task-child__avatar-and-checkbox__checkbox__container",
                    },
                    [
                      _vm.task.has_steps
                        ? _c("i", {
                            staticClass:
                              "fal fa-list-ol goal-task-child__avatar-and-checkbox__checkbox",
                            style: _vm.checkBoxStyle,
                          })
                        : [
                            _vm.allowSetAssistanceOptions(_vm.task)
                              ? _c(
                                  "goal-task-complete-dropdown",
                                  {
                                    ref: "complete-dropdown",
                                    attrs: {
                                      "allow-incomplete": _vm.task.completed,
                                      "provided-assistance":
                                        _vm.task.provided_assistance,
                                      "available-completion-options":
                                        _vm.availableCompletionOptions,
                                    },
                                    on: {
                                      updated: _vm.onCompletionOptionsUpdated,
                                    },
                                  },
                                  [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.bottom",
                                          value: _vm.completedMarkHintText,
                                          expression: "completedMarkHintText",
                                          modifiers: { bottom: true },
                                        },
                                      ],
                                      ref: "complete-checkbox-desktop",
                                      staticClass:
                                        "fa fa-check-square-o goal-task-child__avatar-and-checkbox__checkbox pointer",
                                      style: _vm.checkBoxStyle,
                                    }),
                                  ]
                                )
                              : _c("i", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.bottom",
                                      value: _vm.completedMarkHintText,
                                      expression: "completedMarkHintText",
                                      modifiers: { bottom: true },
                                    },
                                  ],
                                  ref: "complete-checkbox-desktop",
                                  staticClass:
                                    "fa fa-check-square-o goal-task-child__avatar-and-checkbox__checkbox pointer",
                                  style: _vm.checkBoxStyle,
                                  attrs: { tabindex: "0", role: "button" },
                                  on: {
                                    click: _vm.toggleCompletedStatus,
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.toggleCompletedStatus.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                }),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_vm.task.uses_default_avatar || _vm.task.uses_generated_avatar
              ? _c("img", {
                  staticClass: "img-rounded vertical-top",
                  attrs: {
                    src: _vm.task.avatar,
                    width: "80",
                    height: "80",
                    alt: "",
                  },
                })
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "goal-task-child__info",
            class: {
              "no-avatar":
                _vm.task.uses_default_avatar && !_vm.task.uses_generated_avatar,
              "with-checkbox":
                _vm.task.has_steps ||
                _vm.isCompletedMarkEditable(_vm.task) ||
                (_vm.editMode && _vm.isEditable),
            },
          },
          [
            _c(
              "div",
              { staticClass: "goal-task-child__info__name" },
              [
                _vm.statusLabelVisible
                  ? _c(
                      "div",
                      {
                        staticClass: "goal-task-child__status-mark",
                        style: _vm.statusColor,
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.statusLabel) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.routerNavigation
                  ? _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value:
                              _vm.isEditable && _vm.editMode
                                ? _vm.editTaskLabel
                                : undefined,
                            expression:
                              "(isEditable&&editMode)?editTaskLabel:undefined",
                          },
                        ],
                        staticClass: "vertical-middle color-black",
                        attrs: { to: _vm._f("backHrefQuery")(_vm.task.link) },
                        on: { click: _vm.editOrOpenTask },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("cutName")(_vm.task.name, 50)) +
                            "\n                "
                        ),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value:
                              _vm.isEditable && _vm.editMode
                                ? _vm.editTaskLabel
                                : undefined,
                            expression:
                              "(isEditable&&editMode)?editTaskLabel:undefined",
                          },
                        ],
                        staticClass: "vertical-middle color-black",
                        attrs: { href: _vm._f("backHref")(_vm.task.link) },
                        on: { click: _vm.editOrOpenTask },
                      },
                      [_vm._v(_vm._s(_vm._f("cutName")(_vm.task.name, 50)))]
                    ),
                _vm._v(" "),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editMode && _vm.isEditable,
                      expression: "editMode&&isEditable",
                    },
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.editTaskLabel,
                      expression: "editTaskLabel",
                    },
                  ],
                  staticClass:
                    "fa vertical-middle fa-pencil pointer edit-title-pencil",
                  on: { click: _vm.editTask },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("goal-task-time-assignee-panel", {
              attrs: {
                task: _vm.task,
                "edit-mode": _vm.editMode,
                "is-assignee-editable": _vm.isAssigneeEditable,
                "is-editable": _vm.isEditable,
                layout: _vm.layout,
                "in-child": true,
              },
              on: { editDates: _vm.editDates, editAssignee: _vm.editAssignee },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "div" }, [
              _c(
                "span",
                {
                  staticClass: "pointer",
                  class: { bold: _vm.showTags },
                  on: { click: _vm.toggleTags },
                },
                [
                  _c("i", {
                    staticClass: "fa-tags",
                    class: { far: _vm.showTags, fal: !_vm.showTags },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("Tags (" + _vm._s(_vm.task.tags.length) + ")"),
                  ]),
                  _vm._v(" "),
                  _vm.tagsDiffers
                    ? _c("i", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.differsTaskLabel,
                            expression: "differsTaskLabel",
                          },
                        ],
                        staticClass: "fa-exclamation color-blue",
                        class: { far: _vm.showTags, fal: !_vm.showTags },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  class: { bold: _vm.showDescription },
                  on: { click: _vm.toggleDescription },
                },
                [
                  _c("i", {
                    staticClass: "fa-align-left",
                    class: {
                      far: _vm.showDescription,
                      fal: !_vm.showDescription,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Description")]),
                  _vm._v(" "),
                  _vm.descriptionDiffers
                    ? _c("i", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.differsTaskLabel,
                            expression: "differsTaskLabel",
                          },
                        ],
                        staticClass: "fa-exclamation color-blue",
                        class: {
                          far: _vm.showDescription,
                          fal: !_vm.showDescription,
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.task.has_steps
                ? _c(
                    "span",
                    {
                      staticClass: "pointer",
                      class: { bold: _vm.showSteps },
                      on: { click: _vm.toggleSteps },
                    },
                    [
                      _c("span", { staticStyle: { "white-space": "pre" } }, [
                        _c("i", {
                          staticClass: "fa-list-ol",
                          class: { far: _vm.showSteps, fal: !_vm.showSteps },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Steps")]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showTags || _vm.showDescription || _vm.showSteps
        ? _c("div", { staticClass: "goal-task-child__optional-part" }, [
            _vm.showSteps
              ? _c(
                  "div",
                  {},
                  [
                    _c("goal-task-steps", {
                      staticStyle: { "margin-left": "0" },
                      attrs: {
                        "allow-set-assistance-options":
                          _vm.allowSetAssistanceOptions(_vm.task),
                        small: true,
                        steps: _vm.task.steps,
                        "task-id": _vm.task.id,
                        completable: _vm.isCompletedMarkEditable(_vm.task),
                      },
                      on: {
                        taskCompleted: _vm.onTaskCompletedByStep,
                        taskInCompleted: _vm.onTaskInCompletedByStep,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showTags
              ? _c("div", {}, [
                  _vm.editMode && _vm.isEditable
                    ? _c(
                        "div",
                        { staticStyle: { margin: "5px 0" } },
                        [
                          _c("vue-tags-input", {
                            attrs: {
                              "max-tags": 20,
                              tags: _vm.tags,
                              "autocomplete-items": _vm.autocompleteItems,
                            },
                            on: {
                              "tags-changed": _vm.tagsChanged,
                              "before-adding-tag": _vm.beforeAddingTag,
                              "before-deleting-tag": _vm.beforeDeletingTag,
                            },
                            model: {
                              value: _vm.tag,
                              callback: function ($$v) {
                                _vm.tag = $$v
                              },
                              expression: "tag",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticStyle: { margin: "5px 0" } },
                        [
                          _vm.task.tags && _vm.task.tags.length
                            ? _c("task-tags-list", {
                                attrs: { tags: _vm.task.tags },
                              })
                            : _c("span", [
                                _c(
                                  "i",
                                  { staticStyle: { "font-size": "14px" } },
                                  [_vm._v("No tags added")]
                                ),
                              ]),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showDescription
              ? _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "goal-task-component-desktop__content__description",
                    },
                    [
                      _vm.taskDescription
                        ? _c("span", [_vm._v(_vm._s(_vm.taskDescription))])
                        : _c("span", [
                            _c("i", { staticStyle: { "font-size": "14px" } }, [
                              _vm._v("No description added"),
                            ]),
                          ]),
                      _vm._v(" "),
                      _vm.taskDescription && _vm.isDescriptionLargerThenPossible
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "goal-task-component-desktop__content__description__show-all",
                              on: { click: _vm.showWholeDescription },
                            },
                            [_vm._v("show all")]
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }