<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="title"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ title }}</h3>
        </div>

        <div class="wiki-log-popup__content-container">
            <div class="">
                <goal-log-popup-log-item
                        :log="log"
                        v-for="(log, i) in logsToDisplay"
                        :key="i"
                ></goal-log-popup-log-item>
                <div class="alert alert-info" v-if="!logsToDisplay.length && !loading">
                    No logs yet
                </div>

                <div class="row margin-15-top color-tag-gray-dark" v-if="loading && !initialized">
                    <div class="col-sm-12 text-center">
                        <div>
                            <i class="fas fa-spinner fa-spin fa-4x"></i>
                        </div>
                        <div>
                            Loading...
                        </div>
                    </div>
                </div>

                <infinite-loading @infinite="loadInfinite">
                    <span slot="no-more"></span>
                    <span slot="no-results"></span>
                </infinite-loading>
            </div>
        </div>

        <div slot="footer">
            <button class="btn btn-mm btn-green" @click="close">OK</button>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from '../../../../vue/mixins/PopupPromiseMixin';
    import BasicModal from '../../../../vue/common_components/BasicModal';
    import InfiniteScrollMixin from '../../../../vue/mixins/InfiniteScrollMixin';
    import goal_logs_resource from './goal_logs_resource';
    import GoalLogPopupLogItem from './GoalLogPopupLogItem.vue';

    export default {
        name: 'GoalLogPopup',
        components: {
            GoalLogPopupLogItem,
            BasicModal
        },
        mixins: [PopupPromiseMixin, InfiniteScrollMixin],
        props: {
            goalId: Number,
        },
        data() {
            return {
                getResultsOnMount: false,
                limit: 100,
                showDimmerOnLoadMore: false,
            };
        },
        computed: {
            title() {
                return `${this.$customTerm('Goal')} Log`;
            },
            logsToDisplay() {
                return this.results;
            },
        },
        methods: {
            getListEndpointArgs() {
                return { goal: this.goalId };
            },
            getListEndpoint() {
                return goal_logs_resource.list;
            },
            async showedHook() {
                this.getResults(false);
            },
            setInitial() {
            },
            async loadInfinite($state) {
                const resultsAmount = this.results.length;
                await this.getResults(false);
                if (this.results.length === resultsAmount) {
                    $state.complete();
                } else {
                    $state.loaded();
                }
            }
        },
        mounted() {
        }

    };
</script>

<style scoped lang="scss">
  .wiki-log-popup {
    &__content-container {
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: hidden;

      &::v-deep {
        a {
          font-weight: bold;
        }
      }
    }
  }
</style>
