var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("assigned task "),
    _c("strong", [
      _vm._v(
        "#" +
          _vm._s(_vm.log.context.taskId) +
          " " +
          _vm._s(_vm.log.context.taskName)
      ),
    ]),
    _vm._v(" to "),
    _c(
      "strong",
      [_c("simple-link", { attrs: { target: _vm.log.context_userprofile } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }