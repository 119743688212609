var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("goal-tags-filter-panel"),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-8" },
          [_c("goal-create-panel", { attrs: { "allow-filter": false } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-lg-4" },
          [
            _c(
              "btn-group",
              {
                staticClass: "completion-state-filter-panel",
                attrs: { justified: "" },
              },
              [
                _c(
                  "btn",
                  {
                    staticClass: "filter-button",
                    class: {
                      "filter-button_active":
                        _vm.activeCompletionFilter ===
                        _vm.COMPLETION_FILTER_OPTIONS.TO_DO,
                    },
                    attrs: { justified: "" },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveCompletionFilter(
                          _vm.COMPLETION_FILTER_OPTIONS.TO_DO
                        )
                      },
                    },
                  },
                  [
                    _c("sliding-counter", {
                      attrs: {
                        prefix: "To Do (",
                        postfix: ")",
                        count: _vm.visibleTasksCounters.notCompleted,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "btn",
                  {
                    staticClass: "filter-button filter-button__middle",
                    class: {
                      "filter-button_active":
                        _vm.activeCompletionFilter ===
                        _vm.COMPLETION_FILTER_OPTIONS.COMPLETED,
                    },
                    attrs: { justified: "" },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveCompletionFilter(
                          _vm.COMPLETION_FILTER_OPTIONS.COMPLETED
                        )
                      },
                    },
                  },
                  [
                    _c("sliding-counter", {
                      attrs: {
                        prefix: "Completed (",
                        postfix: ")",
                        count: _vm.visibleTasksCounters.completed,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "btn",
                  {
                    staticClass: "filter-button",
                    class: {
                      "filter-button_active":
                        _vm.activeCompletionFilter ===
                        _vm.COMPLETION_FILTER_OPTIONS.ALL,
                    },
                    attrs: { justified: "" },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveCompletionFilter(
                          _vm.COMPLETION_FILTER_OPTIONS.ALL
                        )
                      },
                    },
                  },
                  [
                    _c("sliding-counter", {
                      attrs: {
                        prefix: "All (",
                        postfix: ")",
                        count: _vm.visibleTasksCounters.all,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "goal-tasks-list" },
        [
          _vm._l(_vm.visibleTasks, function (task) {
            return _c("goal-task", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTaskFilteredByTags(task),
                  expression: "isTaskFilteredByTags(task)",
                },
              ],
              key: task.id + "" + (task.children ? task.children.length : ""),
              attrs: {
                "initial-task": task,
                layout: _vm.goalTaskLayout,
                "is-completed-mark-editable": _vm.isTaskCompletedMarkEditable,
                "is-editable": _vm.isTaskEditable(task),
                "is-assignee-editable": _vm.isTaskAssigneeEditable(task),
                "allow-multiple-assign": _vm.isAdmin && !_vm.filterIsActive,
                "allow-drag": _vm.isAdmin && !_vm.filterIsActive,
                "delete-forbidden": _vm.filterIsActive,
                "without-children": _vm.withoutChildren,
                "is-admin": _vm.isAdmin,
                "edit-mode": _vm.isTaskInEditMode(task),
                "tags-in-project": _vm.tagsInProject,
                "hide-completed-children":
                  _vm.activeCompletionFilter ===
                  _vm.COMPLETION_FILTER_OPTIONS.TO_DO,
                "hide-in-completed-children":
                  _vm.activeCompletionFilter ===
                  _vm.COMPLETION_FILTER_OPTIONS.COMPLETED,
                availableCompletionOptions: _vm.availableCompletionOptions,
                goal: _vm.goal,
                "router-navigation": true,
              },
            })
          }),
          _vm._v(" "),
          !_vm.visibleTasks.length
            ? _c(
                "div",
                {
                  staticClass: "alert alert-info",
                  staticStyle: { "font-size": "16px" },
                },
                [
                  _vm.assigneesInFilter?.length || _vm.tagsInFilter?.length
                    ? _c("span", [
                        _c("strong", [_vm._v("Oops!")]),
                        _vm._v(
                          " No " +
                            _vm._s(_vm._f("customTerm")("Tasks")) +
                            " match your search\n            "
                        ),
                      ])
                    : _c("span", [
                        _c("strong", [_vm._v("Oops!")]),
                        _vm._v(
                          " Looks like there are no " +
                            _vm._s(_vm._f("customTerm")("tasks")) +
                            " in that " +
                            _vm._s(_vm._f("customTerm")("Goal")) +
                            " yet\n            "
                        ),
                      ]),
                  _vm._v(" "),
                  [
                    _vm.COMPLETION_FILTER_OPTIONS.TO_DO,
                    _vm.COMPLETION_FILTER_OPTIONS.COMPLETED,
                  ].includes(_vm.activeCompletionFilter) &&
                  _vm.visibleTasksCounters.all
                    ? _c("span", { staticClass: "margin-5-top" }, [
                        _c("br"),
                        _c("br"),
                        _vm._v("\n\n                There’"),
                        _vm.visibleTasksCounters.all === 1
                          ? _c("span", [_vm._v("s")])
                          : _c("span", [_vm._v("re")]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.visibleTasksCounters.all)),
                        ]),
                        _vm._v(" hiding in another list.\n                "),
                        _c(
                          "strong",
                          {
                            staticClass: "text-underline pointer",
                            attrs: { tabindex: "0", role: "link" },
                            on: {
                              click: function ($event) {
                                _vm.setActiveCompletionFilter(
                                  _vm.activeCompletionFilter ===
                                    _vm.COMPLETION_FILTER_OPTIONS.TO_DO
                                    ? _vm.COMPLETION_FILTER_OPTIONS.COMPLETED
                                    : _vm.COMPLETION_FILTER_OPTIONS.TO_DO
                                )
                              },
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                _vm.setActiveCompletionFilter(
                                  _vm.activeCompletionFilter ===
                                    _vm.COMPLETION_FILTER_OPTIONS.TO_DO
                                    ? _vm.COMPLETION_FILTER_OPTIONS.COMPLETED
                                    : _vm.COMPLETION_FILTER_OPTIONS.TO_DO
                                )
                              },
                            },
                          },
                          [_vm._v("Click here to view")]
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.visibleTasksCounters.all.length < _vm.flattenedTasks.length &&
      !_vm.isAdmin &&
      !_vm.showOtherUsersTasks &&
      !_vm.assigneesInFilter.length &&
      !_vm.tagsInFilter.length
        ? _c("div", {}, [
            _c(
              "strong",
              {
                staticClass: "pointer text-underline",
                staticStyle: { "font-size": "16px" },
                attrs: { role: "button", tabindex: "0" },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.expandOtherUsersTasks.apply(null, arguments)
                  },
                  click: _vm.expandOtherUsersTasks,
                },
              },
              [_vm._v("Show Tasks assigned to other users")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showOtherUsersTasks &&
      !_vm.assigneesInFilter.length &&
      !_vm.tagsInFilter.length
        ? _c("div", {}, [
            _c(
              "strong",
              {
                staticClass: "pointer text-underline",
                staticStyle: { "font-size": "16px" },
                attrs: { role: "button", tabindex: "0" },
                on: { click: _vm.hideOtherUsersTasks },
              },
              [_vm._v("Hide Tasks assigned to other users")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }