/**
 * Created by orion on 6/24/17.
 */
import urls from 'DjangoUrls';
import _ from 'lodash';
import axios from 'axios';

let {DjangoUrls} = urls;
CalendarEventTaskLinksResource.$inject = ['$http'];
const PREFIX = 'api.v1:event_task_links-';

function CalendarEventTaskLinksResource($http) {
    return {
        link_task_to_event,
        unlink_task_from_event,
        load_pre_linked_tasks,
        load_info_about_linked_occurrences,
        link_task_to_event_by_fast_toggle,
        unlink_task_from_event_by_fast_toggle,
    };


    function link_task_to_event(calendarOwnerId, eventId, eventDate, requestedDates, type, taskId, syncedChildrenIdList = undefined) {
        const url = DjangoUrls[PREFIX + "link-task-to-event"](eventId);
        return axios({
            method: 'POST',
            url: url,
            data: {
                calendar_owner_id: calendarOwnerId,
                id: taskId,
                dates: requestedDates,
                type,
                event_date: eventDate,
                children_to_update_id_list: syncedChildrenIdList
            }
        });
    }

    function unlink_task_from_event(calendarOwnerId, eventId, requestedDate, taskId, syncedChildrenIdList = undefined) {
        const url = DjangoUrls[PREFIX + "unlink-task-from-event"](eventId);
        return axios({
            method: 'POST',
            url: url,
            data: {
                calendar_owner_id: calendarOwnerId,
                id: taskId,
                date: requestedDate,
                children_to_update_id_list: syncedChildrenIdList
            }
        });
    }

    function load_pre_linked_tasks(calendarOwnerId, eventId, requestedDate, profilesIdList) {
        const url = DjangoUrls[PREFIX + "load-pre-linked-tasks"](eventId);
        return axios({
            method: 'GET',
            url: url,
            params: {
                calendar_owner_id: calendarOwnerId,
                date: requestedDate,
                id_list: profilesIdList
            }
        });
    }

    function load_info_about_linked_occurrences(calendarOwnerId, eventId, requestedDate, taskId) {
        const url = DjangoUrls[PREFIX + "load-info-about-linked-occurrences"](eventId);
        return axios({
            method: 'GET',
            url: url,
            params: {
                calendar_owner_id: calendarOwnerId,
                date: requestedDate,
                id: taskId,
            }
        });
    }

    function link_task_to_event_by_fast_toggle(calendarOwnerId, eventId, requestedDate, taskId, syncWithChildrenIdList = undefined) {
        const url = DjangoUrls[PREFIX + "link-task-to-event-by-fast-toggle"](eventId);
        return axios({
            method: 'POST',
            url: url,
            data: {
                calendar_owner_id: calendarOwnerId,
                event_date: requestedDate,
                id: taskId,
                children_to_update_id_list: syncWithChildrenIdList,
            }
        });
    }


    function unlink_task_from_event_by_fast_toggle(calendarOwnerId, eventId, requestedDate, taskId, syncWithChildrenIdList = undefined) {
        const url = DjangoUrls[PREFIX + "unlink-task-from-event-by-fast-toggle"](eventId);
        return axios({
            method: 'POST',
            url: url,
            data: {
                calendar_owner_id: calendarOwnerId,
                event_date: requestedDate,
                id: taskId,
                children_to_update_id_list: syncWithChildrenIdList,
            }
        });
    }

}

export default CalendarEventTaskLinksResource;
