<template>
    <span>removed attachment
        <strong>#{{ log.context.attachmentId }}</strong> from {{ 'Task' | customTerm }}
        <strong>#{{ log.context.taskId }} {{ log.context.taskName }}</strong>
    </span>
</template>

<script>

    export default {
        name: 'TaskAttachmentDeleted',
        props: {
            log: Object,
        }
    };
</script>

<style scoped lang="scss">

</style>
