<template>
    <div class="">
        <div class="log" v-if="currentComponent">
            <img class="log__avatar"
                 :src="log.actor.avatar"
                 alt="avatar"
            >
            <div class="log__content">
                <div class="log__details">
                    <div>
                        <simple-link :target="log.actor"></simple-link>
                    </div>
                    <i class="text--secondary text-caption"> {{ formattedTime }} </i>
                </div>
                <component
                        :is="currentComponent"
                        :log="log"
                ></component>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import GoalCreated from '~/angular/app/goals/goal_logs/action_specific_components/GoalCreated.vue';
    import TaskCreated from '~/angular/app/goals/goal_logs/action_specific_components/TaskCreated.vue';
    import TaskUpdated from '~/angular/app/goals/goal_logs/action_specific_components/TaskUpdated.vue';
    import GoalUpdated from '~/angular/app/goals/goal_logs/action_specific_components/GoalUpdated.vue';
    import GoalHidden from '~/angular/app/goals/goal_logs/action_specific_components/GoalHidden.vue';
    import GoalUnhidden from '~/angular/app/goals/goal_logs/action_specific_components/GoalUnhidden.vue';
    import GoalDeleted from '~/angular/app/goals/goal_logs/action_specific_components/GoalDeleted.vue';
    import GoalTasksReordered from '~/angular/app/goals/goal_logs/action_specific_components/GoalTasksReordered.vue';
    import GoalStoryUpdated from '~/angular/app/goals/goal_logs/action_specific_components/GoalStoryUpdated.vue';
    import GoalAttachmentAdded from '~/angular/app/goals/goal_logs/action_specific_components/GoalAttachmentAdded.vue';
    import GoalAttachmentDeleted
        from '~/angular/app/goals/goal_logs/action_specific_components/GoalAttachmentDeleted.vue';
    import TaskAttachmentAdded from '~/angular/app/goals/goal_logs/action_specific_components/TaskAttachmentAdded.vue';
    import TaskAttachmentDeleted
        from '~/angular/app/goals/goal_logs/action_specific_components/TaskAttachmentDeleted.vue';
    import TaskDeleted from '~/angular/app/goals/goal_logs/action_specific_components/TaskDeleted.vue';
    import TaskAssigneeUpdated from '~/angular/app/goals/goal_logs/action_specific_components/TaskAssigneeUpdated.vue';
    import TaskMarkedCompleted from '~/angular/app/goals/goal_logs/action_specific_components/TaskMarkedCompleted.vue';
    import TaskMarkedIncomplete
        from '~/angular/app/goals/goal_logs/action_specific_components/TaskMarkedIncomplete.vue';
    import TaskStepMarkedCompleted
        from '~/angular/app/goals/goal_logs/action_specific_components/TaskStepMarkedCompleted.vue';
    import TaskStepMarkedIncomplete
        from '~/angular/app/goals/goal_logs/action_specific_components/TaskStepMarkedIncomplete.vue';

    const COMPONENTS = {
        GOAL_CREATED: GoalCreated,
        TASK_CREATED: TaskCreated,
        TASK_UPDATED: TaskUpdated,
        GOAL_UPDATED: GoalUpdated,
        GOAL_HIDDEN: GoalHidden,
        GOAL_UNHIDDEN: GoalUnhidden,
        GOAL_DELETED: GoalDeleted,
        GOAL_TASKS_REORDERED: GoalTasksReordered,
        GOAL_STORY_UPDATED: GoalStoryUpdated,
        GOAL_ATTACHMENT_ADDED: GoalAttachmentAdded,
        GOAL_ATTACHMENT_DELETED: GoalAttachmentDeleted,
        TASK_ATTACHMENT_ADDED: TaskAttachmentAdded,
        TASK_ATTACHMENT_DELETED: TaskAttachmentDeleted,
        TASK_DELETED: TaskDeleted,
        TASK_ASSIGNEE_UPDATED: TaskAssigneeUpdated,
        TASK_MARKED_COMPLETED: TaskMarkedCompleted,
        TASK_MARKED_INCOMPLETE: TaskMarkedIncomplete,

        TASK_STEP_MARKED_COMPLETED: TaskStepMarkedCompleted,
        TASK_STEP_MARKED_INCOMPLETE: TaskStepMarkedIncomplete,
    };

    export default {
        name: 'GoalLogPopupLogItem',
        props: {
            log: Object,
        },
        computed: {
            currentComponent() {
                return COMPONENTS[this.log.action];
            },
            formattedTime() {
                return moment.tz(this.log.created_at, 'Europe/London').local().format('DD/MM/YY HH:mm');
            }
        },
    };
</script>

<style scoped lang="scss">
  .log {
    padding: 8px 8px 8px 0;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ddd;

    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &__content {
      width: 100%;
    }

    &__details {
      display: flex;
      justify-content: space-between;

      @media (max-width: 599px) {
        flex-direction: column;
      }
    }
  }

</style>
