var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("updated " + _vm._s(_vm._f("customTerm")("Task")) + "\n    "),
    _c("strong", [
      _vm._v(
        "#" +
          _vm._s(_vm.log.context.taskId) +
          " " +
          _vm._s(_vm.log.context.taskName)
      ),
    ]),
    _vm._v(".\n    "),
    _vm.log.context.taskNewName
      ? _c("span", [
          _vm._v("New name: "),
          _c("strong", [_vm._v(_vm._s(_vm.log.context.taskNewName))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.log.context.taskNewDescription
      ? _c("span", [
          _vm._v("New description: "),
          _c("i", [_vm._v(_vm._s(_vm.log.context.taskNewDescription))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.log.context.newAvatarId
      ? _c("span", [
          _vm._v("New avatar: "),
          _c("strong", [_vm._v("#" + _vm._s(_vm.log.context.newAvatarId))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }