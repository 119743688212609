var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("updated " + _vm._s(_vm._f("customTerm")("Goal")) + "\n    "),
    _vm.log.context.goalNewName
      ? _c("span", [
          _vm._v("New name: "),
          _c("strong", [_vm._v(_vm._s(_vm.log.context.goalNewName))]),
          _vm._v("."),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.log.context.goalNewDescription
      ? _c("span", [
          _vm._v("New description: "),
          _c("i", [_vm._v(_vm._s(_vm.log.context.goalNewDescription))]),
          _vm._v("."),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.log.context.hideTasks !== undefined
      ? _c("span", [
          _vm._v(_vm._s(_vm._f("customTerm")("Tasks")) + " are "),
          _c("i", [
            _vm._v(_vm._s(_vm.log.context.hideTasks ? "Hidden" : "Visible")),
          ]),
          _vm._v("."),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.log.context.newAvatarId
      ? _c("span", [
          _vm._v("New avatar: "),
          _c("strong", [_vm._v("#" + _vm._s(_vm.log.context.newAvatarId))]),
          _vm._v("."),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.log.context.newAudioTitle
      ? _c("span", [
          _vm._v("New Audio Title: "),
          _c("strong", [_vm._v("#" + _vm._s(_vm.log.context.newAudioTitle))]),
          _vm._v("."),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.log.context.categoryName
      ? _c("span", [
          _vm._v("New Category: "),
          _c("strong", [_vm._v("#" + _vm._s(_vm.log.context.categoryName))]),
          _vm._v("."),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }