<template>
    <div class="goal_tasks_list__container">

        <h3 style="font-size: 24px; margin-bottom: 5px" v-show="mediaAttachments.length">Media Attachments</h3>
        <div class="alert alert-info" v-if="!mediaAttachments.length&&!otherAttachments.length">There has been nothing
            added yet
        </div>
        <div>
            <div v-for="mediaAttachment in mediaAttachments"
                 :key="mediaAttachment.id"
                 style="position: relative; display: inline-block;">
                <div v-show="isAdmin&&editMode"
                     @click="removeAttachment(mediaAttachment,'media')"
                     style="position: absolute;
    right: 3px;
    top: 3px;
    z-index: 1;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #E6493B;
    color: white;
    text-align: center;
    line-height: 35px;
    font-size: 25px;"><i class="fa fa-times"></i></div>
                <folder-item @click="showAttachment(mediaAttachment)" :attachment="mediaAttachment.media_attachment"
                             :show-tooltip="false"></folder-item>
            </div>

        </div>

        <h3 v-show="otherAttachments.length">Other Attachments</h3>
        <div v-for="attachment in otherAttachments"
             :key="attachment.id"
             style="display: inline-block;">
            <a :href="attachment.goal_attachment.link" style="text-decoration: none" target="_blank"
               v-if="attachment.type==='goal'">
                <vue-stuff-preview
                        :stuff-item="attachment.goal_attachment"
                        stuff-type="goal"
                        :animation="true"
                        :deletable="isAdmin&&editMode"
                        @delete="removeAttachment(attachment,'other')"
                ></vue-stuff-preview>
            </a>
            <a :href="attachment.story_attachment.link" style="text-decoration: none" target="_blank"
               v-if="attachment.type==='story'">
                <vue-stuff-preview
                        :stuff-item="attachment.story_attachment"
                        stuff-type="story"
                        :animation="true"
                        :deletable="isAdmin&&editMode"
                        @delete="removeAttachment(attachment,'other')"
                ></vue-stuff-preview>
            </a>
        </div>

        <div style="margin-top: 10px; text-align: right">
            <button class="btn btn-mm btn-green" @click="attach()"><i class="fa fa-plus"></i> Add Attachments
            </button>
        </div>

    </div>
</template>

<script>
    import Vue from "vue";
    import _ from 'lodash';


    export default {
        name: "GoalDetailPageMainAttachments",
        data() {
            return {
                mediaAttachments: [],
                otherAttachments: [],
            };
        },
        computed: {
            isAdmin() {
                return this.$store.state.goalDetailPageStore.isAdmin;
            },
            editMode() {
                return this.$store.state.goalDetailPageStore.editMode;
            },
            FolderItemProxy() {
                class FolderItemProxy {
                    constructor(attachment) {
                        this.item = attachment.media_attachment;
                        this.attachment = attachment;
                    }

                    get id() {
                        return this.attachment.id;
                    }
                }

                return FolderItemProxy;
            }
        },
        methods: {
            attach() {
                const attachmentFactory = Vue.getAngularModule('attachmentFactory');
                const GoalAttachmentsResource = Vue.getAngularModule('GoalAttachmentsResource');
                attachmentFactory.add_attachments('goal_attachments', GoalAttachmentsResource, this.$route.params.goalId, true)
                    .then(res => {
                        this.mediaAttachments = _.concat(this.mediaAttachments, res[0].map(i => i));
                        // this.otherAttachments = _.concat(this.otherAttachments, res[1]);
                        Vue.notifications.success('Added');
                    });
            },
            removeAttachment(attachment, type) {
                const GoalAttachmentsResource = Vue.getAngularModule('GoalAttachmentsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const $q = Vue.getAngularModule('$q');
                simplePopupFactory.show_popup('Delete Attachment', 'Are you sure you want to delete this attachment?', 'Delete', 'Cancel', 'btn-red')
                    .then(() => {
                        $rootScope.show_dimmer();
                        return GoalAttachmentsResource.delete_attachment(this.$route.params.goalId, attachment.id);
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        if (type === 'media') {
                            this.mediaAttachments.splice(this.mediaAttachments.indexOf(attachment), 1);
                        } else {
                            this.otherAttachments.splice(this.otherAttachments.indexOf(attachment), 1);
                        }
                        Vue.notifications.success('Deleted');
                    }, err => Vue.notifications.error(err || "Error"))
                    .finally($rootScope.hide_dimmer);

            },
            fetchAttachments() {
                const GoalAttachmentsResource = Vue.getAngularModule('GoalAttachmentsResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GoalAttachmentsResource.get_attachments(this.$route.params.goalId)
                    .then(resp => {
                        let attachment_partitions = _.partition(resp.data, attachment => attachment.type === 'media');
                        this.mediaAttachments = attachment_partitions[0];
                        this.otherAttachments = attachment_partitions[1];
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            showAttachment(item) {
                if (item.media_attachment.type === 'uploaded_document') {
                    let openInNewTab = function (url) {
                        let win = window.open(url, '_blank');
                        win.focus();
                    };
                    openInNewTab(item.media_attachment.uploaded_document.document_url);

                } else {
                    let only_media_items = this.mediaAttachments.filter(item => item.media_attachment.type !== 'uploaded_document').map(item=>item.media_attachment);
                    const viewMediaFactory = Vue.getAngularModule('viewMediaFactory');
                    viewMediaFactory.show_media(only_media_items, only_media_items.indexOf(item.media_attachment));
                }
            }
        },
        mounted() {
            this.fetchAttachments();
        }
    };
</script>

<style scoped>

</style>
