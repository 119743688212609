<template>
    <div class="">
        <div class="access-forbidden-warning"></div>
        <div class="main-task-content" v-if="showMainPart">
            <div class="header-panel">
                <div class="row">
                    <div class="col-xs-4" style="padding-left:22px;">
                        <div class="row">
                            <div class="col-xs-12">
                                <img :src="task.avatar" width="90" height="90"
                                     alt="Task avatar"
                                     class="visible-print header-panel__avatar-for-print">
                                <div :style="taskAvatarStyle" class="header-panel__avatar-container"></div>

                            </div>
                        </div>
                    </div>
                    <div class="col-xs-8 text-right header-panel__simple-header-right-part hidden-print">

                        <folder-button-edit-toggle v-if="allowDelete" :edit-mode="editMode"
                                                   @toggleEditMode="v=>editMode=v"/>


                        <vue-folder-button
                                :target="backUrl"
                                ref="backButton"
                                text="Back"
                                aria-label="Back"
                                role="button"
                                color="blue"
                                v-if="isCurrentUserAuthenticated"
                                icon-class="fa-mail-reply"
                        ></vue-folder-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12" style="padding-left:22px;">
                        <span v-if="taskEditable&&editMode"
                              class="header-panel__header_title hidden-print pointer text-underline"
                              tabindex="0"
                              role="button"
                              @click="editTask"
                              @keydown.enter="editTask"
                        >{{ taskNameInHeader }} <i class="fa fa-pencil"
                        ></i>
                        </span>
                        <span v-else
                              class="header-panel__header_title hidden-print"
                        >{{ taskNameInHeader }}</span>
                        <div class="header-panel__header_title visible-print">{{ task.name }}</div>
                        <div>{{ 'Task'|customTerm }} created by
                            <strong>
                                <simple-link :target="task.created_by" tab-index="-1"></simple-link>
                            </strong> on {{ task.created_at | gbdate }}.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <task-main-info
                            v-if="task"
                            :initialTask="task"
                            :goal-category="goalCategory"
                            :linked-events="linkedEvents"
                            :is-goal-admin="isAdmin"
                            :availableCompletionOptions="availableCompletionOptions"
                            :edit-mode="editMode"
                            @updateDescription="editTask"
                            @updateAssignee="editAssignee"
                    ></task-main-info>
                </div>
            </div>
            <div>
                <div class="single-attachment-container" :class="{'pointer':attachmentCanBeShownInPopup}"
                     v-if="singleAttachmentMode">
                    <vue-inline-media :item="attachments[0].media_attachment" max-height="300px"
                                      @click.native="()=>attachmentCanBeShownInPopup?showAttachment(attachments[0].media_attachment):undefined"/>
                </div>
                <template v-else>
                    <folder-item
                            :attachment="attachment.media_attachment"
                            v-for="attachment in attachments"
                            :key="attachment.id"
                            :size="90"
                            :allow-delete="canEditAttachments"
                            @delete="del(attachment)"
                            @click="showAttachment(attachment.media_attachment)"
                    ></folder-item>
                </template>
                <div
                        class="hidden-print mm-icon add-attachment"
                        style="
                                display: inline-block;
                                vertical-align: middle;
                                width: 50px;
                                height: 50px;
                                cursor: pointer;
                                background-size: contain;
                                margin-left: 10px;
                                margin-bottom: 10px;
                                "
                        @click='addAttachment'
                        @keydown.enter='addAttachment'
                        aria-label="Add Attachment"
                        role="button"
                        tabindex="0"
                        v-if="canEditAttachments&&editMode"
                        v-tooltip="'Add Attachment'"
                >
                </div>
            </div>
        </div>
        <comments-list-infinite-scroll
                :target-id="taskId"
                :comments-resource="commentsResource"

                :allow-add="allowAdd"
                :allow-like="allowLike"
                :allow-delete="allowDelete&&editMode"
                :allow-reply-expand="true"

                :allowed-media-types="['video', 'audio', 'image', 'document']"

                :initial-reply-to="initialReplyTo"
        ></comments-list-infinite-scroll>
        <a href="#" aria-hidden="true" @focus="focusToBackButton"></a>
    </div>
</template>

<script>
    import GoalTaskDetailResource from '../../../projects/task_detail_resource';
    import Vue from 'vue';
    import { CurrentUserClass } from 'shared/CurrentUser';
    import TaskMainInfo from './TaskMainInfo';
    import TaskDetailCompleteCheckbox from '../../../projects/task_detail_page/TaskDetailCompleteCheckbox';
    import BackButtonMixin from '../../../../../vue/mixins/BackButtonMixin';
    import { customTermFilter } from '~/vue/filters/CustomTerm';
    import { DjangoUrls } from 'DjangoUrls';
    import CommentsListInfiniteScroll from 'shared/comments/CommentsListInfiniteScroll';
    import ActivityReplyToSupportMixin from '../../../../../vue/mixins/ActivityReplyToSupportMixin';
    import VueInlineMedia from '~/angular/app/view_media/vue_inline_media_render_directive/VueInlineMedia.vue';
    import FolderButtonEditToggle from '~/vue/common_components/FolderButton/FolderButtonEditToggle.vue';
    import GoalTaskAssigneeMixin
        from '~/angular/app/projects/GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskAssigneeMixin';

    export default {
        name: 'GoalTaskPage',
        mixins: [BackButtonMixin, ActivityReplyToSupportMixin, GoalTaskAssigneeMixin],
        components: {
            FolderButtonEditToggle,
            VueInlineMedia,
            CommentsListInfiniteScroll,
            TaskMainInfo,
            TaskDetailCompleteCheckbox,
        },
        props: {
            goalId: {
                type: [Number, String]
            },
            taskId: {
                type: [Number, String]
            },
        },
        data() {
            return {
                task: undefined,
                isAdmin: false,
                availableCompletionOptions: [],
                goalCategory: undefined,
                linkedEvents: [],
                attachments: [],

                angularCommentsInitialized: false,

                editMode: false,
            };
        },
        computed: {
            goal() {
                return { id: this.goalId }; // for mixin. fixme later
            },
            singleAttachmentMode() {
                if (this.editMode) {
                    return false;
                }
                return (this.attachments.length === 1) && (this.attachments[0].type === 'media') && ([
                    'image',
                    'youtube_video',
                    'vimeo_video',
                    'upl_video',
                    'uploaded_audio'
                ].includes(this.attachments[0].media_attachment.type));
            },
            attachmentCanBeShownInPopup() {
                return this.singleAttachmentMode && (this.attachments[0].media_attachment.type === 'image');
            },
            commentsResource() {
                return Vue.getAngularModule('ActionComments');
            },
            allowAdd() {
                return this.isCurrentUserAuthenticated && new CurrentUserClass().has_permission('post-comments');
            },
            allowLike() {
                return this.isCurrentUserAuthenticated;
            },
            allowDelete() {
                return this.isCurrentUserAuthenticated && this.isAdmin;
            },
            defaultBackUrl() {
                return (this.task && this.task.goal) ? this.task.goal.link : DjangoUrls['index']();
            },
            isSupportedUser() {
                return new CurrentUserClass().is_circle_user();
            },
            canEditAttachments() {
                if (this.isSupportedUser) {
                    return false;
                }
                if (!this.task) {
                    return;
                }
                return this.isAdmin || this.task.created_by.id === new CurrentUserClass().id;
            },
            showMainPart() {
                return this.task !== undefined;
            },
            linkToProject() {
                if (!this.task) {
                    return;
                }
                return this.task.goal.link;
            },
            isCurrentUserAuthenticated() {
                return new CurrentUserClass().is_authenticated();
            },
            taskAvatarStyle() {
                if (!this.task) {
                    return;
                }
                return { 'background-image': `url('${this.task.avatar}')` };
            },
            taskNameInHeader() {
                return this.task?.name;
            },
            taskEditable() {
                if (!this.task) {
                    return;
                }
                const createdByCurrentUser = this.task.created_by && this.task.created_by.id === new CurrentUserClass().id;
                return this.isAdmin || createdByCurrentUser;
            },
            accessLinkIconColor() {
                if (!this.task) {
                    return;
                }
                if (this.task.available_by_code) {
                    return 'grass-green';
                } else {
                    return 'tag-gray';
                }
            },
        },
        methods: {
            focusToBackButton() {
                this.$refs.backButton?.$el?.focus();
            },
            suggestAutoJoin(project) {
                let GoalResource = Vue.getAngularModule('GoalResource');
                let $rootScope = Vue.getAngularModule('$rootScope');
                let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                simplePopupFactory.show_popup(`Join ${customTermFilter('Goal')}`, `You are not a member of this ${customTermFilter('Goal')}, do you want to join it?`, 'Yes', 'No')
                    .then(y => {
                        $rootScope.show_dimmer();
                        GoalResource.join_goal(project.id)
                            .then(resp => {
                                    if (resp.data.status === 'joined') {
                                        Vue.notifications.success('Joined');
                                        document.location.reload();
                                        return;
                                    }
                                    if (resp.data.status === 'wait_confirmation') {
                                        Vue.notifications.success('Join request sent');
                                        this.goBack();
                                    }
                                },
                                err => {
                                    Vue.notifications.error(`Can't join ${customTermFilter('Goal')}`);
                                    this.goBack();
                                });
                    }, n => {
                        this.goBack();
                    });
            },
            editTask() {
                let goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
                let $rootScope = Vue.getAngularModule('$rootScope');
                let GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                goalTaskFactory.edit_main_options(this.task)
                    .then(res => {
                        let data_for_save = {
                            name: res.name,
                            description: res.description,
                            auto_avatar: res.auto_avatar
                        };
                        if (!res.media_for_avatar && !res.avatar) {
                            data_for_save.reset_avatar = true;
                        } else {
                            if (res.media_for_avatar) {
                                data_for_save.media_id_for_avatar = res.media_for_avatar.pk;
                            }
                        }
                        $rootScope.show_dimmer();
                        return GoalTaskResource.update_main_options(this.task.id, data_for_save);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        Object.assign(this.task, resp.data);
                        Vue.notifications.success('Updated');
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            printTask() {
                if (this.editMode) {
                    return;
                }
                window.print();
            },
            del(attachment) {
                let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                let $rootScope = Vue.getAngularModule('$rootScope');
                let TaskAttachmentsResource = Vue.getAngularModule('TaskAttachmentsResource');
                let $q = Vue.getAngularModule('$q');

                simplePopupFactory.show_popup('Delete Attachment', 'Are you sure you want to delete this attachment?', 'Delete', 'Cancel', 'btn-red')
                    .then(() => {
                        $rootScope.show_dimmer();
                        return TaskAttachmentsResource.delete_attachment(this.task.id, attachment.id);
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        this.attachments.splice(this.attachments.indexOf(attachment), 1);
                        Vue.notifications.success('Deleted');
                    }, err => Vue.notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            showAttachment(item) {
                let simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                let viewMediaFactory = Vue.getAngularModule('viewMediaFactory');
                if (item.type === 'uploaded_document' || item.type === 'link') {
                    let openInNewTab = function (url) {
                        let win = window.open(url, '_blank');
                        win.focus();
                    };
                    if (item.type === 'uploaded_document') {
                        openInNewTab(item.uploaded_document.document_url);
                        return;
                    }
                    if (item.type === 'link') {
                        simplePopupFactory.show_popup('Open Link', `Are you sure you want to open link <br>"<i>${item.name}</i>" ?`, 'Yes', 'Cancel')
                            .then(y => {
                                openInNewTab(item.link.url);
                            });
                    }

                } else {
                    let only_media_items = this.attachments.map(a => a.media_attachment).filter(ma => ma.type !== 'uploaded_document' && ma.type !== 'link');
                    viewMediaFactory.show_media(only_media_items, only_media_items.indexOf(item));
                }
            },
            addAttachment() {
                let attachmentFactory = Vue.getAngularModule('attachmentFactory');
                let TaskAttachmentsResource = Vue.getAngularModule('TaskAttachmentsResource');
                attachmentFactory.add_attachments('task_attachments', TaskAttachmentsResource, this.task.id, true)
                    .then(res => {
                        this.attachments = _.concat(this.attachments, res[0]);
                        Vue.notifications.success('Added');
                    });
            },
            getTaskData() {
                let GoalTaskDetailResource = Vue.getAngularModule('GoalTaskDetailResource');
                let $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GoalTaskDetailResource.get_task_data(this.taskId)
                    .then(({ data }) => {
                        if (data.does_not_has_access_but_can_join) {
                            $rootScope.hide_dimmer();
                            this.suggestAutoJoin(data.project);
                            return;
                        }
                        this.task = data.task;
                        this.isAdmin = data.is_admin;
                        this.availableCompletionOptions = data.available_assistance;
                        this.goalCategory = data.goal_category;
                        this.linkedEvents = data.linked_events;
                        this.attachments = data.attachments;
                        $rootScope.hide_dimmer();
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            }
        },
        mounted() {
            this.getTaskData();
        },
        metaInfo() {
            const taskTerm = this.$customTerm('Task');
            const title = `${this.task ? this.$options.filters.cutName(this.task.name, 20) + ' - ' : ''}${taskTerm}`;
            return {
                title,
            };
        }
    };
</script>

<style scoped lang="scss">
  @import "../../../../../angular/app/styles/mixins";

  .header-panel {
    min-height: 50px;
    background-color: #E2E2E2;
    padding: 5px;
    margin-bottom: 10px;

    &__avatar-container {
      background-size: cover;
      width: 90px;
      height: 90px;
      border-radius: 5px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__avatar-for-print {
      width: 90px;
      height: 90px;
      float: left;
      margin-right: 5px;
    }

    &__show-all-link {
      font-size: 16px;
      font-weight: normal;
      cursor: pointer;
    }
  }

  .single-attachment-container {
    @include wide_item_container();
    padding: 10px;
  }

</style>
