var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-modal",
    {
      attrs: { "aria-label": _vm.header, role: "dialog" },
      on: { hide: _vm.close },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("basic-modal-close-button", { ref: "closeButton" }),
          _vm._v(" "),
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.header) + "\n            "),
            _vm.allowClone
              ? _c("i", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: `Copy data from another {$ 'Task'|customTerm $}`,
                      expression:
                        "`Copy data from another {$ 'Task'|customTerm $}`",
                    },
                  ],
                  staticClass: "fa fa-clone pointer color-blue",
                  attrs: { tabindex: "0", role: "button" },
                  on: {
                    click: _vm.loadAnotherTask,
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.loadAnotherTask.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm._f("customTerm")("Task")) +
                  " Name:\n                    "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.taskName,
                    expression: "taskName",
                  },
                ],
                ref: "taskName",
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "", maxlength: "255" },
                domProps: { value: _vm.taskName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.taskName = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("hr", { staticClass: "hr10" }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "10px" } },
            [
              _c("div", { staticClass: "col-sm-12" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm._f("customTerm")("Task")) +
                    " Description:\n                    "
                ),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { rows: "5", placeholder: "Add more words below?" },
                  domProps: { value: _vm.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.description = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("hr"),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        !_vm.avatar
          ? _c("div", { staticClass: "col-sm-4" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-blue text-left",
                  staticStyle: { width: "173px", padding: "7px 15px 13px 0" },
                  on: { click: _vm.addAvatar },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "vertical-middle",
                      staticStyle: {
                        "text-align": "center",
                        display: "inline-block",
                      },
                    },
                    [_c("i", { staticClass: "fa fa-image fa-2" })]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "vertical-middle" }, [
                    _vm._v(" + Add Picture"),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.avatar
          ? _c("div", { staticClass: "col-sm-8" }, [
              _vm._v(
                "\n            The " +
                  _vm._s(_vm._f("customTerm")("Task")) +
                  " will automatically select a picture if you do not add one here.\n        "
              ),
            ])
          : _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _vm._v("\n            Avatar:"),
                _c("br"),
                _vm._v(" "),
                _c("media-preview", {
                  attrs: { item: _vm.avatar, "allow-delete": "" },
                  on: { onDelete: _vm.resetAvatar },
                }),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _vm.allowQuickCreate
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-12 text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    attrs: { disabled: !_vm.taskName },
                    on: { click: _vm.createNow },
                  },
                  [_vm._v("Create\n                ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm",
                    attrs: { disabled: !_vm.taskName },
                    on: { click: _vm.next },
                  },
                  [
                    _vm._v("More Options "),
                    _c("i", { staticClass: "fa fa-chevron-right" }),
                  ]
                ),
              ]),
            ])
          : _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-12 text-right" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-mm", on: { click: _vm.close } },
                  [_vm._v(_vm._s(_vm.cancelButton))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-mm btn-green",
                    attrs: { disabled: !_vm.taskName },
                    on: { click: _vm.next },
                  },
                  [_vm._v(_vm._s(_vm.saveButton) + "\n                ")]
                ),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }