<template>
    <div class="step" :style="[stepSize, stepAppearanceStyle, draggingStyle, resizingStyle, stepBackgroundColor]"
         @mousedown.stop.prevent
         @touchend="onTouchEnd"
         v-show="isVisible"
    >
        <div class="inner" :style="innerBlockStyle">
            <span class="align-helper" style="width: 1px"></span>
            <!--<i class="fa" v-if="statusIcon" :class="statusIcon"></i>-->

            <div class="inline-block" v-tooltip="avatarTooltip">
                <img :src="task.avatar" class="img-circle" :style="avatarStyle"/>
            </div>
            <div class="inline-block" style="vertical-align: top">
                <div v-tooltip="taskNameTooltip">
                    <a @mousedown.stop @touchstart.stop @click.stop="clickOnHeader" :href="task.link |backHrefQuery"
                       ref="taskname"
                       :style="headerStyle">{{ task.name |cutName(25)}}</a>
                </div>

                <span class="fa-stack" style="vertical-align: top; font-size: 17px" v-if="task.comments_count">
                        <i class="fal fa-comment fa-stack-2x"></i>
                        <a class="fa-stack-1x" style="color: #333; text-decoration: none" :href="task.link |backHrefQuery">{{task.comments_count}}</a>
                </span>
            </div>


            <i class="fa fa-arrows-alt dragdrop-grip" :style="resizeGripStyle" style="font-size: 22px"
               @mousedown.stop.prevent="dragGripClicked"
               @touchstart.stop.prevent="dragGripTouched"
               v-if="isEditable"
               v-show="editMode"
               @click.stop.prevent></i>
            <div class="resize-grip"
                 v-if="insideTimeline&&isEditable"
                 v-show="editMode"
                 @mousedown.stop.prevent="resizeGripClicked"
                 @touchstart.stop.prevent="resizeGripTouched"
                 @click.stop.prevent></div>
        </div>
    </div>
</template>

<script>

    import config from '../../../../goals/config.js';

    const TASK_NAME_MAX_LENGTH_SYMBOLS = 25;


    import MouseEventsMixin from '../../../../../../vue/mixins/MouseEventsMixin.js';
    import moment from 'moment';
    import offsetBus from './offsetBus.js';
    import {fullScreenBus, FULL_SCREEN_BUS_EVENTS} from './fullScreenBus.js';
    import {getTaskBackgroundColor} from "../../../../projects/GoalDetailPage/common";

    const API_DATE_FORMAT = config.API_DATE_FORMAT;

    export default {
        mixins: [MouseEventsMixin],
        name: "Step",
        props: {
            task: {
                type: Object
            },
            direction: {
                type: String
            }
        },
        data() {
            return {
                isResizing: false,
                isDragging: false,

                offsetBeforeDrag: undefined,
                positionBeforeDragX: undefined,
                positionBeforeDragY: undefined,
                currentPositionX: undefined,
                currentPositionY: undefined,

                widthBeforeResize: undefined,
                resizingWidth: undefined,

                offset: 0,
                isMounted: false
            };
        },
        computed: {
            fullScreenEnabled() {
                return this.$store.state.timeline.fullScreenEnabled;
            },
            editMode() {
                return this.$store.state.goalDetailPageStore.editMode;
            },
            isEditable() {
                return this.$store.state.goalDetailPageStore.isAdmin || this.task.created_by.id === this.$store.state.goalDetailPageStore.currentUser.id;
            },
            isVisible() {
                return this.$store.state.goalDetailPageStore.tagsInFilter.length === 0 || _.intersection(this.task.tags, this.$store.state.goalDetailPageStore.tagsInFilter).length > 0;
            },
            insideTimeline() {
                return this.task.start_date && this.task.end_date;
            },
            width() {
                if (!this.insideTimeline) {
                    return this.$store.state.timeline.colWidth * 3;
                }
                if (this.isResizing) {
                    return Math.round(this.resizingWidth / this.$store.state.timeline.colWidth) * this.$store.state.timeline.colWidth - 4;
                } else {
                    let colsCount;
                    const realStartDate = moment(this.task.start_date, API_DATE_FORMAT);
                    const realEndDate = moment(this.task.end_date, API_DATE_FORMAT);
                    if (this.$store.getters.scale === 'weeks') {
                        const visibleStartDate = realStartDate.clone().weekday(0);
                        colsCount = Math.max(1, Math.ceil(realEndDate.diff(visibleStartDate, 'days') / 7));
                    } else {
                        colsCount = (realEndDate.diff(realStartDate, 'days') + 1);
                    }
                    return colsCount * this.$store.state.timeline.colWidth - 4;
                }
            },
            stepSize() {
                return {
                    height: `${this.$store.state.timeline.rowHeight - 10}px`,
                    width: `${this.width}px`
                };
            },
            innerBlockStyle() {
                return {
                    height: `${this.$store.state.timeline.rowHeight - 10}px`,
                    'white-space': 'nowrap'
                };
            },
            stepAppearanceStyle() {
                const style = {};
                if (this.direction === 'toLeft') {
                    style['float'] = 'right';
                }
                if (this.isDragging) {
                    style['opacity'] = 0.8;
                }
                if (this.isResizing) {
                    style['cursor'] = 'col-resize';
                } else {
                    style['cursor'] = 'default';
                }
                return style;
            },
            stepBackgroundColor() {
                let color;
                color = getTaskBackgroundColor(this.task)

                if (this.isResizing) {
                    return {'background-color': color};
                }

                return {
                    'background-color': color,
                    'border-left': `2px solid ${color}`,
                    'border-top': `2px solid ${color}`,
                    'border-bottom': `2px solid ${color}`,
                };
            },
            resizeGripStyle() {
                const style = {'cursor': 'grab'};
                if (this.isDragging) {
                    style['cursor'] = 'grabbing';
                }
                return style;
            },
            draggingStyle() {
                const style = {};
                if (this.$store.state.timeline.draggedTask) {
                    style['pointer-events'] = 'none';
                }
                if (!this.isDragging) {
                    return style;
                }
                if (!this.offsetBeforeDrag) {
                    this.offsetBeforeDrag = this.offset;
                }
                const xCoord = this.currentPositionX - this.positionBeforeDragX - (this.insideTimeline ? (this.offsetBeforeDrag - this.offset) : 0);
                return {
                    'z-index': 9999,
                    'pointer-events': 'none',
                    'cursor': 'grabbing',
                    'transform': `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${xCoord}, ${this.currentPositionY - this.positionBeforeDragY}, 0, 1)`
                };
            },
            resizingStyle() {
                if (!this.isResizing) {
                    return;
                }
                return {
                    'border-left': '2px dotted gray',
                    'border-top': '2px dotted gray',
                    'border-bottom': '2px dotted gray',
                    'opacity': 0.8,
                    'z-index': 3
                };
            },
            avatarStyle() {
                const sizeInPx = `${this.$store.state.timeline.rowHeight - 20}px`;
                return {
                    height: sizeInPx,
                    width: sizeInPx,
                };
            },
            headerStyle() {
                return {
                    'vertical-align': 'top',
                    'font-size': '16px',
                    'font-weight': 'bold',
                    'color': '#333'
                };
            },
            taskNameTooltip() {
                if (this.isResizing || this.isDragging) {
                    return;
                }

                if (this.task.name.length > TASK_NAME_MAX_LENGTH_SYMBOLS) {
                    return this.task.name;
                }
                if (this.isMounted && this.width < this.$refs.taskname.offsetWidth + 70) {
                    return this.task.name;
                }

            },
            avatarTooltip() {
                if (this.width > 100 || this.isResizing || this.isDragging) {
                    return;
                }
                return this.task.name;
            }
        },
        methods: {
            clickOnHeader(evt) {
                if (this.fullScreenEnabled) {
                    evt.preventDefault();
                    fullScreenBus.$emit(FULL_SCREEN_BUS_EVENTS.STEP_CLICKED_IN_FULLSCREEN_MODE, this.task);
                }
            },
            offsetChanged(offset) {
                this.offset = offset;
            },
            handleResizeStart(coords) {
                this.widthBeforeResize = this.resizingWidth = this.width;
                this.isResizing = true;
                this.$store.commit('timeline/set_dragged_task');
                this.positionBeforeDragX = coords.x;
            },
            resizeGripClicked(e) {
                this.handleResizeStart({x: e.clientX, y: e.clientY});
            },
            resizeGripTouched(e) {
                if (e.targetTouches.length !== 1) {
                    return;
                }
                this.handleResizeStart({
                    x: Math.round(e.targetTouches[0].clientX),
                    y: Math.round(e.targetTouches[0].clientY)
                });
            },
            handleDragStart(coords) {
                this.isDragging = true;
                this.$store.commit('timeline/set_dragged_task', this.task);
                this.currentPositionX = this.positionBeforeDragX = coords.x;
                this.currentPositionY = this.positionBeforeDragY = coords.y;
                this.offsetBeforeDrag = undefined;
                offsetBus.$on('offsetChanged', this.offsetChanged);
            },
            dragGripClicked(e) {
                this.handleDragStart({x: e.clientX, y: e.clientY});
            },
            dragGripTouched(e) {
                if (e.targetTouches.length !== 1) {
                    return;
                }
                this.handleDragStart({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY});
            },
            onMouseUp() {
                if (this.isResizing) {
                    this.isResizing = false;
                    this.$store.commit('timeline/reset_task_resizing');

                    if (Math.round(this.resizingWidth / this.$store.state.timeline.colWidth) * this.$store.state.timeline.colWidth - 4 === this.widthBeforeResize) {
                        return;
                    }
                    this.$store.dispatch('timeline/resize_task', {
                        task: this.task,
                        days_count: Math.round(this.resizingWidth / this.$store.state.timeline.colWidth)
                    });
                }
                if (this.isDragging) {
                    this.isDragging = false;
                    this.$store.commit('timeline/reset_dragged_task');
                    offsetBus.$off('offsetChanged', this.offsetChanged);
                }
            },

            onPointerMove(coords) {
                if (this.isDragging) {
                    this.currentPositionX = coords.x;
                    this.currentPositionY = coords.y;
                }
                if (this.isResizing) {
                    let delta = coords.x - this.positionBeforeDragX;
                    let tmpWidth = Math.max(this.widthBeforeResize + delta, this.$store.state.timeline.colWidth);
                    this.resizingWidth = tmpWidth;
                }

            },
            onTouchEnd(e) {
                if (!this.isDragging) {
                    return;
                }
                if (e.changedTouches.length !== 1) {
                    return;
                }
                let coords = {x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY};
                let el = document.elementFromPoint(coords.x, coords.y);
                let x = document.createEvent("MouseEvent");
                x.initMouseEvent("mouseup", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                el.dispatchEvent(x);
            }
        },
        watch: {
            isResizing(n, o) {
                document.body.style.cursor = n ? 'col-resize' : 'default';
            }
        },
        mounted() {
            this.isMounted = true;
        }
    };
</script>

<style scoped lang="scss">
    .step {
        margin-left: 2px;
        float: left;
        margin-top: 5px;
        position: relative;
        z-index: 2;
        border-radius: 5px;
        overflow: hidden;
        transition: background-color ease 0.3s;

    }

    .resize-grip {
        position: absolute;
        cursor: col-resize;
        right: 0;
        height: 100%;
        display: block;
        background-color: #333;
        @media (min-width: 1100px) {
            width: 4px;
        }

        @media (min-width: 800px) and (max-width: 1099px) {
            width: 8px;
        }

        @media (max-width: 799px) {
            width: 16px;
        }

        top: 0;
        opacity: 0.4;
    }

    .dragdrop-grip {
        position: absolute;
        top: 2px;
        left: 2px;
    }

    .inner {
        min-width: 275px;
    }
</style>
