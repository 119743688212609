<template>
    <span>deleted {{ 'Task' | customTerm}} <strong>#{{ log.context.taskId }} {{ log.context.taskName }}</strong></span>
</template>

<script>

    export default {
        name: 'TaskDeleted',
        props: {
            log: Object,
        }
    };
</script>

<style scoped lang="scss">

</style>
