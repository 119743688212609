var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      staticClass: "table table-striped task-detail-info",
      staticStyle: { "font-size": "16px" },
    },
    [
      _c(
        "tbody",
        [
          _c("tr", { staticClass: "hidden-print" }, [
            _c("th", { staticStyle: { width: "138px" } }, [
              _vm._v(_vm._s(_vm._f("customTerm")("Goal")) + ":"),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("strong", { staticClass: "hidden-print" }, [
                _c("a", { attrs: { href: _vm.task.goal.link } }, [
                  _vm._v(_vm._s(_vm._f("cutName")(_vm.task.goal.name, 200))),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "hidden-print" }, [
            _c("th", { staticStyle: { width: "138px" } }, [
              _vm._v(_vm._s(_vm._f("customTerm")("Goal")) + " Owner:"),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("simple-link-with-avatar", {
                  attrs: { target: _vm.task.goal.owner },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "visible-print" }, [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("strong", { staticClass: "visible-print" }, [
                _vm._v(_vm._s(_vm.task.goal.name)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("Category:")]),
            _vm._v(" "),
            _c("td", [
              _vm.goalCategory
                ? _c("img", {
                    staticClass: "border-goal img-rounded",
                    attrs: {
                      src: _vm.goalCategory.image,
                      width: "30",
                      height: "30",
                      alt: _vm.goalCategory.name,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.goalCategory ? _vm.goalCategory.name : "Default")
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tr",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.linkedEvents.length,
                  expression: "linkedEvents.length",
                },
              ],
            },
            [
              _c("th", [_vm._v("Linked Events:")]),
              _vm._v(" "),
              _c(
                "td",
                _vm._l(_vm.linkedEvents, function (linkedEvent, i) {
                  return _c(
                    "span",
                    [
                      _c("task-main-info-linked-event", {
                        attrs: { event: linkedEvent },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: i < _vm.linkedEvents.length - 1,
                              expression: "i<(linkedEvents.length-1)",
                            },
                          ],
                        },
                        [_vm._v(", ")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _vm.task.start_date || _vm.editMode
            ? _c("tr", [
                _c("th", [_vm._v("Start Date:")]),
                _vm._v(" "),
                !_vm.editMode
                  ? _c("td", [
                      _vm._v(_vm._s(_vm._f("gbdate")(_vm.task.start_date))),
                    ])
                  : _c("td", [
                      _c(
                        "span",
                        {
                          attrs: { tabindex: "0", role: "button" },
                          on: {
                            click: _vm.updateDates,
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.updateDates.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm.task.start_date
                            ? _c("span", { staticClass: "text-underline" }, [
                                _vm._v(
                                  _vm._s(_vm._f("gbdate")(_vm.task.start_date))
                                ),
                              ])
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "color-tag-gray-dark text-underline",
                                },
                                [_vm._v("Unscheduled")]
                              ),
                          _vm._v(" "),
                          _c("i", { staticClass: "far fa-pencil" }),
                        ]
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.task.end_date || _vm.editMode
            ? _c("tr", [
                _c("th", [_vm._v("End Date:")]),
                _vm._v(" "),
                !_vm.editMode
                  ? _c("td", [
                      _vm._v(_vm._s(_vm._f("gbdate")(_vm.task.end_date))),
                    ])
                  : _c("td", [
                      _c(
                        "span",
                        {
                          attrs: { tabindex: "0", role: "button" },
                          on: {
                            click: _vm.updateDates,
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.updateDates.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm.task.end_date
                            ? _c("span", { staticClass: "text-underline" }, [
                                _vm._v(
                                  _vm._s(_vm._f("gbdate")(_vm.task.end_date))
                                ),
                              ])
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "color-tag-gray-dark text-underline",
                                },
                                [_vm._v("Unscheduled")]
                              ),
                          _vm._v(" "),
                          _c("i", { staticClass: "far fa-pencil" }),
                        ]
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.task.description || _vm.editMode
            ? _c("tr", [
                _c("th", [
                  _vm._v(
                    _vm._s(_vm._f("customTerm")("Task")) + " Description:"
                  ),
                ]),
                _vm._v(" "),
                !_vm.editMode
                  ? _c("td", [
                      _c("i", { staticStyle: { "white-space": "pre-wrap" } }, [
                        _vm._v(_vm._s(_vm.task.description)),
                      ]),
                    ])
                  : _c("td", [
                      _c(
                        "span",
                        {
                          attrs: { tabindex: "0", role: "button" },
                          on: { click: _vm.updateDescription },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "text-underline",
                              staticStyle: { "white-space": "pre-wrap" },
                            },
                            [_vm._v(_vm._s(_vm.task.description))]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "far fa-pencil" }),
                        ]
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _vm._v(_vm._s(_vm._f("customTerm")("Task")) + " Status:"),
            ]),
            _vm._v(" "),
            _c(
              "td",
              { class: { "color-grass-green-dark": _vm.task.completed } },
              [
                _vm.allowSetAssistanceOptions &&
                _vm.completable &&
                !_vm.task.has_steps &&
                !_vm.editMode
                  ? _c(
                      "goal-task-complete-dropdown",
                      {
                        attrs: {
                          "provided-assistance": _vm.task.provided_assistance,
                          "allow-incomplete": _vm.task.completed,
                          "available-completion-options":
                            _vm.availableCompletionOptions,
                        },
                        on: { updated: _vm.onCompletionOptionsUpdated },
                      },
                      [
                        _c(
                          "span",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "vertical-middle",
                                class: {
                                  "color-grass-green-dark": _vm.task.completed,
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa",
                                  class: _vm.statusIcon,
                                }),
                                _vm._v(" "),
                                _c(
                                  "strong",
                                  { staticClass: "text-underline pointer" },
                                  [_vm._v(_vm._s(_vm.statusText))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.task.completed
                              ? _c("task-main-info-completion-data", {
                                  staticClass: "text-underline pointer",
                                  attrs: { "completable-instance": _vm.task },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm.completable &&
                    !_vm.allowSetAssistanceOptions &&
                    !_vm.task.has_steps &&
                    !_vm.editMode
                  ? _c(
                      "span",
                      {
                        class: { "color-grass-green-dark": _vm.task.completed },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "vertical-middle",
                            attrs: { tabindex: "0", role: "button" },
                            on: {
                              click: _vm.toggleCompletedStatus,
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.toggleCompletedStatus.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa",
                              class: _vm.statusIcon,
                            }),
                            _vm._v(" "),
                            _c(
                              "strong",
                              { staticClass: "text-underline pointer" },
                              [_vm._v(_vm._s(_vm.statusText))]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.task.completed
                          ? _c("task-main-info-completion-data", {
                              attrs: { "completable-instance": _vm.task },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c("span", { staticClass: "vertical-middle" }, [
                          _c("i", { staticClass: "fa", class: _vm.statusIcon }),
                          _vm._v(" "),
                          _c("strong", [_vm._v(_vm._s(_vm.statusText))]),
                        ]),
                        _vm._v(" "),
                        _vm.task.completed
                          ? _c("task-main-info-completion-data", {
                              attrs: { "completable-instance": _vm.task },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.showTaskStepsInInfo &&
          ((_vm.task.steps && _vm.task.steps.length) || _vm.editMode)
            ? _c("tr", [
                _c("th", [_vm._v("Task Sub-steps:")]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm._l(_vm.task.steps, function (step, i) {
                      return _c(
                        "div",
                        [
                          _c("task-main-info-task-step", {
                            attrs: {
                              step: step,
                              "task-id": _vm.task.id,
                              completable: _vm.completable,
                              "allow-set-assistance-options":
                                _vm.allowSetAssistanceOptions,
                              "allow-edit": _vm.canEditTask && _vm.editMode,
                              index: i + 1,
                              availableCompletionOptions:
                                _vm.availableCompletionOptions,
                            },
                            on: {
                              taskCompleted: _vm.taskCompleted,
                              taskInCompleted: _vm.taskInCompleted,
                              editStep: _vm.editStep,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.canEditTask && _vm.editMode
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "task-detail-info__add-step-label hidden-print",
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.addStep.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("+ Add\n                    Sub-step")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.editMode || _vm.task.assignee
            ? _c("tr", [
                _c("th", [_vm._v("Who is doing it?")]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.task.assignee
                      ? _c("simple-link-with-avatar", {
                          style: _vm.assigneeAvatarStyle,
                          attrs: { target: _vm.task.assignee },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.task.assignee
                      ? _c("img", {
                          staticClass: "img-circle",
                          attrs: {
                            src: _vm.assigneeAvatar,
                            width: "40",
                            height: "40",
                            alt: "Anybody",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.task.assignee
                      ? _c("strong", [_vm._v("Anybody")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editMode
                      ? _c("i", {
                          staticClass: "far fa-pencil pointer margin-5-left",
                          staticStyle: { "font-size": "25px" },
                          attrs: { tabindex: "0", role: "button" },
                          on: {
                            click: _vm.updateAssignee,
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.updateAssignee.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.viewerCanConfirmTaskAssignment
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "28px",
                              "vertical-align": "middle",
                              "margin-left": "10px",
                            },
                          },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "OK, I'm on to it",
                                  expression: "'OK, I\\'m on to it'",
                                },
                              ],
                              staticClass: "fa fa-check color-green pointer",
                              attrs: {
                                "aria-label": "OK, I'm on to it",
                                role: "button",
                                tabindex: "0",
                              },
                              on: {
                                click: _vm.acceptTaskAssignment,
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.acceptTaskAssignment.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: "Sorry, I can't do this",
                                  expression: "'Sorry, I can\\'t do this'",
                                },
                              ],
                              staticClass: "fa fa-times color-red pointer",
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                "aria-label": "Sorry, I can't do this",
                                role: "button",
                                tabindex: "0",
                              },
                              on: {
                                click: _vm.rejectTaskAssignment,
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.rejectTaskAssignment.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.task.tags.length
            ? _c("tr", [
                _c(
                  "th",
                  { attrs: { colspan: "2" } },
                  [
                    _vm._v("Tags:\n            "),
                    _c("task-tags-list", {
                      staticStyle: { display: "inline", "margin-left": "10px" },
                      attrs: { tags: _vm.task.tags },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("step-add-edit-remove-popup", {
            ref: "step-add-edit-remove-popup",
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }