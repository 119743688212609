<template>
    <span>signed {{ 'Task' | customTerm }} <strong>#{{ log.context.taskId }} {{ log.context.taskName |cutName(50) }}</strong> Step <strong>#{{ log.context.stepId }} {{ log.context.stepName }}</strong> off as <strong class="color-grass-green-dark">Complete</strong></span>
</template>

<script>
    export default {
        name: 'TaskStepMarkedCompleted',
        props: {
            log: Object,
        },
    };
</script>

<style scoped lang="scss">

</style>
