<template>
    <basic-modal @hide="close"
                 v-model="opened"
                 :aria-label="header"
                 role="dialog"
    >
        <div slot="header">
            <basic-modal-close-button ref="closeButton"></basic-modal-close-button>
            <h3 class="modal-title">{{ header }}
                <i class="fa fa-clone pointer color-blue"
                   @click="loadAnotherTask"
                   @keydown.enter="loadAnotherTask"
                   tabindex="0"
                   role="button"
                   v-if="allowClone"
                   v-tooltip="`Copy data from another {$ 'Task'|customTerm $}`"
                ></i>
            </h3>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12">
                        {{ 'Task'|customTerm }} Name:
                        <input type="text" v-model="taskName" ref="taskName" placeholder="" class="form-control" maxlength="255"/>
                        <hr class="hr10">
                    </div>
                </div>
                <div class="row" style="margin-top: 10px">
                    <div class="col-sm-12">
                        {{ 'Task'|customTerm }} Description:
                        <textarea v-model="description" rows="5" class="form-control"
                                  placeholder="Add more words below?"></textarea>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4" v-if="!avatar">
                <button class="btn btn-blue text-left" style="width: 173px; padding: 7px 15px 13px 0;"
                        @click="addAvatar">
                <span class="vertical-middle" style="text-align: center;
                        display: inline-block;"> <i class="fa fa-image fa-2"></i></span>
                    <span class="vertical-middle"> + Add Picture</span>
                </button>
            </div>
            <div class="col-sm-8" v-if="!avatar">
                The {{ 'Task' | customTerm }} will automatically select a picture if you do not add one here.
            </div>
            <div class="col-sm-12" v-else>
                Avatar:<br>
                <media-preview :item="avatar" allow-delete @onDelete="resetAvatar"></media-preview>
            </div>
        </div>
        <div slot="footer">
            <div class="row" v-if="allowQuickCreate">
                <div class="col-xs-12 text-right">
                    <button class="btn btn-mm btn-green"
                            :disabled="!taskName"
                            @click="createNow">Create
                    </button>
                    <button class="btn btn-mm"
                            :disabled="!taskName"
                            @click="next">More Options <i class="fa fa-chevron-right"></i></button>

                </div>
            </div>
            <div class="row" v-else>
                <div class="col-xs-12 text-right">
                    <button class="btn btn-mm" @click="close">{{ cancelButton }}</button>
                    <button class="btn btn-mm btn-green" :disabled="!taskName" @click="next"
                    >{{ saveButton }}
                    </button>
                </div>
            </div>
        </div>
    </basic-modal>
</template>

<script>
    import PopupPromiseMixin from '~/vue/mixins/PopupPromiseMixin';

    export default {
        mixins: [PopupPromiseMixin],
        props: {
            allowClone: false,
            header: {
                type: String,
            },
            allowQuickCreate: {
                type: Boolean,
                default: false
            },
            cancelButton: {
                type: String,
                default: 'Cancel',
            },
            saveButton: {
                type: String,
                default: 'Save',
            },
        },
        data() {
            return {
                taskName: '',
                description: '',
                mediaForAvatar: undefined,
                avatar: undefined,
            };
        },
        computed: {
            firstFocusEl(){
              return this.$refs.taskName;
            },
        },
        mounted() {

        },
        methods: {
            setInitial(initialData) {
                this.taskName = initialData.name;
                this.description = initialData.description;
                this.mediaForAvatar = initialData.media_for_avatar;
                this.avatar = initialData.media_for_avatar || (initialData.uses_default_avatar ? undefined : initialData.avatar);
            },

            dumpData() {
                return {
                    name: this.taskName,
                    description: this.description,
                    media_for_avatar: this.mediaForAvatar,
                    avatar: this.avatar
                };
            },
            addAvatar() {
                this.save({
                    type: 'add_media',
                    data: this.dumpData(),
                });
            },

            resetAvatar() {
                this.avatar = undefined;
                this.mediaForAvatar = undefined;
            },
            createNow() {
                this.save({
                    type: 'save',
                    data: Object.assign({ createNow: true }, this.dumpData())
                });
            },
            next() {
                this.save({
                    type: 'save',
                    data: this.dumpData(),
                });
            },
            loadAnotherTask() {
                this.save({
                    type: 'load_another_task',
                    data: this.dumpData(),
                });
            }
        },
    };
</script>

<style scoped lang="scss">

</style>
