<template>
    <span>removed attachment <strong>#{{ log.context.attachmentId }}</strong></span>
</template>

<script>

    export default {
        name: 'GoalAttachmentDeleted',
        props: {
            log: Object,
        }
    };
</script>

<style scoped lang="scss">

</style>
