var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("deleted " + _vm._s(_vm._f("customTerm")("Task")) + " "),
    _c("strong", [
      _vm._v(
        "#" +
          _vm._s(_vm.log.context.taskId) +
          " " +
          _vm._s(_vm.log.context.taskName)
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }