var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("reordered " + _vm._s(_vm._f("customTerm")("Tasks"))),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }