var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _vm.currentComponent
      ? _c("div", { staticClass: "log" }, [
          _c("img", {
            staticClass: "log__avatar",
            attrs: { src: _vm.log.actor.avatar, alt: "avatar" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "log__content" },
            [
              _c("div", { staticClass: "log__details" }, [
                _c(
                  "div",
                  [_c("simple-link", { attrs: { target: _vm.log.actor } })],
                  1
                ),
                _vm._v(" "),
                _c("i", { staticClass: "text--secondary text-caption" }, [
                  _vm._v(" " + _vm._s(_vm.formattedTime) + " "),
                ]),
              ]),
              _vm._v(" "),
              _c(_vm.currentComponent, {
                tag: "component",
                attrs: { log: _vm.log },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }