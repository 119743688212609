var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dropdown",
    {
      ref: "dropdown",
      staticClass: "task-complete__dropdown-form",
      attrs: { "not-close-elements": _vm.ele },
      scopedSlots: _vm._u([
        {
          key: "dropdown",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "text-center task-complete__dropdown-form__header",
                  staticStyle: { padding: "3px" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("customTerm")("Task")) +
                      " completed\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.supportOptions, function (supportOption, i) {
                return _c(
                  "li",
                  {
                    ref: "supportOption" + i,
                    refInFor: true,
                    staticClass: "task-complete__dropdown-form__option",
                    class: { active: _vm.getIsActive(supportOption) },
                    attrs: {
                      tabindex: 0,
                      role: "menuitemcheckbox",
                      "aria-checked": _vm.getIsActive(supportOption)
                        ? "true"
                        : "false",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleOption(supportOption)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.toggleOption(supportOption)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fal",
                      class: {
                        "fa-square": !_vm.getIsActive(supportOption),
                        "fa-check-square": _vm.getIsActive(supportOption),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(supportOption.name) +
                          "\n            "
                      ),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass:
                    "task-complete__dropdown-form__option task-complete__save-button",
                  class: {
                    "task-complete__save-button_disabled": _vm.saveDisabled,
                  },
                  attrs: { tabindex: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.save(true)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.save(true)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fal fa-check" }),
                  _vm._v(" "),
                  _c("span", [_vm._v("\n                Save\n            ")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.allowIncomplete,
                      expression: "allowIncomplete",
                    },
                  ],
                  staticClass: "task-complete__dropdown-form__option",
                  attrs: { tabindex: "0" },
                  on: {
                    click: function ($event) {
                      return _vm.incomplete(true)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.incomplete(true)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fal fa-times" }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("\n                Not completed\n            "),
                  ]),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }