import Vue from 'vue';
import { VISUAL_DATE_FORMAT } from '~/angular/app/events/new_calendar/config';
import GoalTaskResource from '~/angular/app/projects/project_task_resource';
import _ from 'lodash';

export default {
    methods:{
        editAssignee() {
            const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');

            const additionalFilters = [];
            additionalFilters.push({
                name: 'members_type',
                filter: true,
                slot: 'left-from-search',
                value: 'all',
                options: [
                    {
                        value: 'all',
                        'name': 'All Users'
                    },
                    {
                        value: 'non_su',
                        'name': 'Non Supported Users'
                    },
                    {
                        value: 'su',
                        'name': 'Supported Users'
                    },
                    // {value: 'buddy', 'name': 'Buddies'},
                ],
                paramsWhenTrue: 'members_type'
            });
            if (this.eventParamsForTasks) {
                additionalFilters.push({
                    name: 'members_only',
                    text: 'Show members of this Event only',
                    inputType: 'switch',
                    filter: true,
                    value: true,
                    paramsWhenTrue: {
                        from_event: this.eventParamsForTasks.eventId,
                        event_date: this.eventParamsForTasks.eventDateMoment.format(VISUAL_DATE_FORMAT),
                    }
                });
            }

            goalTaskFactory.assignee_options(this.goal.id, {
                additional_filters: additionalFilters,
                info: ' '
            }).then(res => {
                Vue.loadingSpinner.show();
                return GoalTaskResource().update_assignee_options(this.task.id, { id: res });
            }, () => new Promise(() => {
            }))
                .then(resp => {
                    _.merge(this.task, resp.data.updated_task_data);
                    Vue.notifications.success('Updated');
                }, err => Vue.notifications.error(err || 'Error'))
                .finally(Vue.loadingSpinner.hide);
        }
    }
}
