import {PROVIDED_ASSISTANCE_OPTIONS_VALUES} from "../config";
import config from "../../goals/config";

function getTaskStatusCode(task) {
    let special_status;
    if (!task.assignee && task.status !== 'completed') {
        special_status = 'not_in_list';
    }
    if (!special_status&&!task.has_steps) {
        if (task.status === 'completed' && task.provided_assistance) {
            if (task.provided_assistance.map(a => a.code).includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_NOT_ACHIEVED)) {
                special_status = undefined//'completed_but_not_achieved';
            } else if (task.provided_assistance.map(a => a.code).includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_EXCEEDED)) {
                special_status = 'exceeded';
            }
        }
    }
    return special_status || task.status;
}

function getStepColorCode(step) {
    let special_status;
    if (step.completed && step.provided_assistance) {
        if (step.provided_assistance.map(a => a.code).includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_NOT_ACHIEVED)) {
            special_status = undefined;//'completed_but_not_achieved';
        } else if (step.provided_assistance.map(a => a.code).includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_EXCEEDED)) {
            special_status = 'exceeded';
        }
    }
    if (!special_status && step.completed) {
        return 'completed';
    }

    return special_status || 'pending';
}

function getTaskBackgroundColor(task) {
    return config.TASK_COLOR_TABLE_OPACITY[getTaskStatusCode(task)];
}

function getStepColor(task) {
    return config.TASK_COLOR_TABLE[getStepColorCode(task)];
}

export {getTaskBackgroundColor, getStepColor, getTaskStatusCode};
