export default {
    API_DATE_FORMAT: "YYYY-MM-DD",
    TASK_COLOR_TABLE: {
        'in_progress': '#F1F289',
        'overdue': '#eaa29f',
        'completed': '#51AC51',
        'exceeded': '#00b3ee',
        'completed_but_not_achieved': '#dec793',
        'pending': '#9c9c9c',
        'unscheduled': '#fff',
        'not_in_list': '#fff',
    },
    TASK_STATUS_LABEL_COLOR_TABLE: {
        'in_progress': '#bfc172',
        'overdue': '#ee9693',
        'completed': '#51AC51',
        'exceeded': '#00b3ee',
        'completed_but_not_achieved': '#dbc273',
        'pending': '#d2d2d2',
        'unscheduled': '#fff',
        'not_in_list': '#fff',
    },
    TASK_COLOR_TABLE_OPACITY: {
        'in_progress': 'rgba(241,242,137,0.5)',
        'overdue': 'rgba(234, 162, 159, 0.5)',
        'completed': 'rgba(129, 220, 151, 0.5)',
        'exceeded': 'rgba(0,179,238,0.5)',
        'completed_but_not_achieved': 'rgba(222,199,147,0.5)',
        'pending': 'rgba(226, 226, 226, 0.5)',
        'not_in_list': 'rgba(255, 255, 255, 0.8)',
        'unscheduled': 'rgba(255, 255, 255, 0.8)',
    },
    TASK_TEXT_STATUS: {
        'in_progress': 'In Progress',
        'overdue': 'Overdue!',
        'completed': 'Completed',
        'exceeded': 'Exceeded',
        'completed_but_not_achieved': 'Not Achieved',
        'pending': 'Pending',
    },
    EMPTY_ASSIGNEE_AVATAR: '/static/images/icons/question-mark-w.png',
    EMPTY_ASSIGNEE_NAME: 'Not assigned to anyone',
    EMPTY_ASSIGNEE_ID: -1,
};
