var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "counter-container" },
    [
      _c("span", { staticClass: "counter-label" }, [
        _vm._v(_vm._s(_vm.prefix)),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide", mode: "out-in" } }, [
        _c("span", { key: _vm.count, staticClass: "counter counter-label" }, [
          _vm._v(_vm._s(_vm.count)),
        ]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "counter-label" }, [
        _vm._v(_vm._s(_vm.postfix)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }