var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v("signed " + _vm._s(_vm._f("customTerm")("Task")) + " "),
    _c("strong", [
      _vm._v(
        "#" +
          _vm._s(_vm.log.context.taskId) +
          " " +
          _vm._s(_vm._f("cutName")(_vm.log.context.taskName, 50))
      ),
    ]),
    _vm._v(" Step "),
    _c("strong", [
      _vm._v(
        "#" +
          _vm._s(_vm.log.context.stepId) +
          " " +
          _vm._s(_vm.log.context.stepName)
      ),
    ]),
    _vm._v(" off as "),
    _c("strong", { staticClass: "color-grass-green-dark" }, [
      _vm._v("Complete"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }