<template>
    <dropdown ref="dropdown"
              :not-close-elements="ele"
              class="task-complete__dropdown-form"
              v-model="opened"
    >
        <slot></slot>
        <template #dropdown>
            <div class="text-center task-complete__dropdown-form__header" style="padding: 3px">
                {{ 'Task'|customTerm }} completed
            </div>
            <li class="task-complete__dropdown-form__option"
                :tabindex="0"
                v-for="(supportOption, i) in supportOptions"
                :ref="'supportOption'+i"
                :class="{active:getIsActive(supportOption)}"
                role="menuitemcheckbox"
                :aria-checked="getIsActive(supportOption)?'true':'false'"
                @click="toggleOption(supportOption)"
                @keydown.enter="toggleOption(supportOption)"
            >
                <i class="fal"
                   :class="{'fa-square':!getIsActive(supportOption), 'fa-check-square':getIsActive(supportOption)}"
                ></i>
                <span>
                    {{ supportOption.name }}
                </span>
            </li>
            <li class="task-complete__dropdown-form__option task-complete__save-button"
                :class="{'task-complete__save-button_disabled': saveDisabled}"
                tabindex="0"
                @click="save(true)"
                @keydown.enter="save(true)"
            >
                <i class="fal fa-check"></i>
                <span>
                    Save
                </span>
            </li>
            <li v-show="allowIncomplete" class="task-complete__dropdown-form__option"
                tabindex="0"

                @click="incomplete(true)"
                @keydown.enter="incomplete(true)"
            >
                <i class="fal fa-times"></i>
                <span>
                    Not completed
                </span>
            </li>
        </template>
    </dropdown>
</template>

<script>

    import {
        ASSISTANCE_EXCLUSIVE_GROUP,
        PROVIDED_ASSISTANCE_OPTIONS_LABELS,
        PROVIDED_ASSISTANCE_OPTIONS_VALUES
    } from '../../../../config';

    export default {
        name: 'GoalTaskCompleteDropdown',
        props: {
            checkBoxStyle: Object,
            allowIncomplete: {
                type: Boolean,
                default: false
            },
            providedAssistance: {
                type: Array,
                default: () => [],
            },
            availableCompletionOptions: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
                opened: false,
                ele: [],
                selectedSupportOptions: [],
            };
        },
        mounted() {
            this.selectedSupportOptions = this.providedAssistance ? this.providedAssistance.map(o => o.code) : [];
            this.ele.push(this.$refs.dropdown.$el);
        },
        computed: {
            saveDisabled() {
                return !this.selectedSupportOptions.includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_ACHIEVED) &&
                    !this.selectedSupportOptions.includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_EXCEEDED) &&
                    !this.selectedSupportOptions.includes(PROVIDED_ASSISTANCE_OPTIONS_VALUES.TASK_NOT_ACHIEVED);
            },
            supportOptions() {
                const res = [];
                for (const option of this.availableCompletionOptions) {
                    res.push({
                        code: option.code,
                        name: option.name || PROVIDED_ASSISTANCE_OPTIONS_LABELS[option.code]
                    });
                }
                const availableCompletionOptionsCodes = this.availableCompletionOptions.map(o => o.code);
                for (const option of this.providedAssistance) {
                    if (!availableCompletionOptionsCodes.includes(option.code)) {
                        res.push({
                            code: option.code,
                            name: option.name || PROVIDED_ASSISTANCE_OPTIONS_LABELS[option.code]
                        });
                    }
                }
                return res;
            }
        },
        methods: {
            focusOnFirstOption() {
                this.$refs.supportOption0[0]?.focus();
            },
            resetData() {
                this.selectedSupportOptions = [];
            },
            toggleOption(supportOption) {
                if (this.selectedSupportOptions.includes(supportOption.code)) {
                    this.selectedSupportOptions.splice(this.selectedSupportOptions.indexOf(supportOption.code), 1);
                } else {
                    if (ASSISTANCE_EXCLUSIVE_GROUP.includes(supportOption.code)) {
                        for (let option of ASSISTANCE_EXCLUSIVE_GROUP) {
                            const index = this.selectedSupportOptions.indexOf(option);
                            if (index !== -1) {
                                this.selectedSupportOptions.splice(index, 1);
                            }
                        }
                    }
                    this.selectedSupportOptions.push(supportOption.code);
                }
            },
            getIsActive(supportOption) {
                return this.selectedSupportOptions.includes(supportOption.code);
            },
            save(withSupport) {
                if (this.saveDisabled) {
                    return;
                }
                this.opened = false;
                this.$emit('updated', {
                    result: 'complete',
                    withSupport,
                    options: this.selectedSupportOptions.map(o => o),
                });
            },
            incomplete() {
                this.opened = false;
                this.$emit('updated',
                    {
                        result: 'incomplete',
                    }
                );
            },
        }
    };
</script>

<style lang="scss">
  @import "../../../../../styles/const";

  .task-complete {

    &__save-button {
      &_disabled {
        color: $gray;
        cursor: not-allowed !important;

        &:hover, &:active {
          background-color: $tag-gray !important;
          color: white;
        }
      }
    }

    &__dropdown-form {
      color: $black;

      &__header {
        border-bottom: solid 1px $tag-gray;
        background-color: $lightgray;
        border-radius: 5px 5px 0 0;
      }

      .dropdown-menu {
        /*padding: 5px;*/
        margin-top: -3px;
        font-size: 16px;
        width: 230px !important;
        padding: 0 0 5px 0;
        font-weight: bolder;
      }

      &.btn-group.open {
        .dropdown-toggle {
          box-shadow: none;
        }
      }

      &__option {
        .fal {
          width: 14px;
          text-align: center;
        }

        display: block;
        width: 100%;
        min-height: 40px;

        &:not(:last-child) {
          border-bottom: solid 1px $tag-gray;
        }

        cursor: pointer;

        &:hover, &:active {
          background-color: $dark-blue;
          color: white;
        }

        &.active {
          font-weight: bolder;
        }

        font-weight: normal;
        padding: 5px 5px 0 5px;
      }
    }
  }
</style>
