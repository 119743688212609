import {DjangoUrls} from 'DjangoUrls';
import axios from "axios";


const goal_logs_resource = {list};

function list(params) {
    const url = DjangoUrls[`api.v1:goal_logs-list`]();
    return axios({
        method: 'get',
        url,
        params,
    });
}

export default goal_logs_resource;
