<template>
    <span>signed {{ 'Task' | customTerm }} <strong>#{{ log.context.taskId }} {{ log.context.taskName }}</strong> off as <strong class="color-grass-green-dark">Complete</strong></span>
</template>

<script>
    export default {
        name: 'TaskMarkedCompleted',
        props: {
            log: Object,
        },
    };
</script>

<style scoped lang="scss">

</style>
