var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "list", duration: _vm.enableAnimation ? 500 : 0 } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "observe-visibility",
              rawName: "v-observe-visibility",
              value: {
                callback: _vm.visibilityChanged,
                intersection: {
                  rootMargin: "300px 0px 300px 0px",
                },
              },
              expression:
                "{\n          callback: visibilityChanged,\n          intersection: {\n            rootMargin: '300px 0px 300px 0px',\n          },\n        }",
            },
          ],
          staticClass: "goal-task-component",
          class: {
            "under-drag": _vm.dragOverMe,
            "goal-task-component_animated": _vm.enableAnimation,
          },
          style: [_vm.taskBackgroundColor, _vm.dragStyle],
        },
        [
          _vm.isVisibleOnScreen
            ? _c(
                "div",
                {},
                [
                  _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "goal-task-component__content" },
                      [
                        _c("div", {}, [
                          _c(
                            "div",
                            { staticClass: "inline-block vertical-middle" },
                            [
                              _vm.syncIsPossible
                                ? _c("i", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.syncChangesLabel,
                                        expression: "syncChangesLabel",
                                      },
                                    ],
                                    staticClass:
                                      "fa vertical-middle fa-sync pointer",
                                    staticStyle: {
                                      "font-size": "28px",
                                      "margin-right": "5px",
                                    },
                                    style: _vm.syncMarkStyle,
                                    on: { click: _vm.toggleSync },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.task.has_steps
                                ? _c("i", {
                                    staticClass:
                                      "fal fa-list-ol color-tag-gray vertical-middle",
                                    staticStyle: { "font-size": "35px" },
                                    style: _vm.checkBoxStyle,
                                  })
                                : _vm.isCompletedMarkEditable(_vm.task)
                                ? [
                                    _vm.allowSetAssistanceOptions(_vm.task)
                                      ? _c(
                                          "goal-task-complete-dropdown",
                                          {
                                            ref: "complete-dropdown",
                                            attrs: {
                                              "allow-incomplete":
                                                _vm.task.completed,
                                              "provided-assistance":
                                                _vm.task.provided_assistance,
                                              "available-completion-options":
                                                _vm.availableCompletionOptions,
                                            },
                                            on: {
                                              updated:
                                                _vm.onCompletionOptionsUpdated,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value:
                                                    _vm.completedMarkHintText,
                                                  expression:
                                                    "completedMarkHintText",
                                                },
                                              ],
                                              ref: "complete-checkbox",
                                              staticClass:
                                                "fa vertical-middle fa-check-square-o vertical-middle goal-task-component__complete-checkbox",
                                              style: _vm.checkBoxStyle,
                                            }),
                                          ]
                                        )
                                      : _c("i", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.completedMarkHintText,
                                              expression:
                                                "completedMarkHintText",
                                            },
                                          ],
                                          ref: "complete-checkbox",
                                          staticClass:
                                            "fa vertical-middle fa-check-square-o vertical-middle goal-task-component__complete-checkbox",
                                          style: _vm.checkBoxStyle,
                                          attrs: {
                                            tabindex: "0",
                                            role: "button",
                                          },
                                          on: {
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.toggleCompletedStatus.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            click: _vm.toggleCompletedStatus,
                                          },
                                        }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.statusLabelVisible
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "goal-task-component__status-mark",
                                  style: _vm.statusColor,
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.statusLabel) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "goal-task-assignee-and-time__counters-part",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "goal-task-assignee-and-time__comments-counter-container inline-block",
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fa-stack",
                                        staticStyle: {
                                          "vertical-align": "top",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fal fa-comment fa-stack-2x",
                                        }),
                                        _vm._v(" "),
                                        _vm.routerNavigation
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass: "fa-stack-1x",
                                                staticStyle: {
                                                  color: "#333",
                                                  "text-decoration": "none",
                                                },
                                                attrs: {
                                                  to: _vm._f("backHrefQuery")(
                                                    _vm.task.link
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.task.comments_count
                                                  )
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                staticClass: "fa-stack-1x",
                                                staticStyle: {
                                                  color: "#333",
                                                  "text-decoration": "none",
                                                },
                                                attrs: {
                                                  href: _vm._f("backHrefQuery")(
                                                    _vm.task.link
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.task.comments_count
                                                  )
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.task.attachments_count
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "goal-task-assignee-and-time__attachments-counter inline-block",
                                    },
                                    [
                                      _vm.routerNavigation
                                        ? _c(
                                            "router-link",
                                            {
                                              staticStyle: {
                                                "text-decoration": "none",
                                              },
                                              attrs: {
                                                to: _vm._f("backHrefQuery")(
                                                  _vm.task.link
                                                ),
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fal fa-paperclip",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.task.attachments_count
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                "text-decoration": "none",
                                              },
                                              attrs: {
                                                href: _vm._f("backHrefQuery")(
                                                  _vm.task.link
                                                ),
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fal fa-paperclip",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.task.attachments_count
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "goal-task-assignee-and-time__additional-buttons inline-block",
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isDesktop
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "goal-task-component__checkbox-and-avatar",
                                class: {
                                  "no-avatar":
                                    _vm.task.uses_default_avatar &&
                                    !_vm.task.uses_generated_avatar,
                                },
                              },
                              [
                                (_vm.routerNavigation &&
                                  !_vm.task.uses_default_avatar) ||
                                _vm.task.uses_generated_avatar
                                  ? _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: _vm._f("backHrefQuery")(
                                            _vm.task.link
                                          ),
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "goal-task-component__avatar-part__avatar",
                                          style: _vm.taskAvatarStyle,
                                        }),
                                      ]
                                    )
                                  : !_vm.task.uses_default_avatar ||
                                    _vm.task.uses_generated_avatar
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm._f("backHrefQuery")(
                                            _vm.task.link
                                          ),
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "goal-task-component__avatar-part__avatar",
                                          style: _vm.taskAvatarStyle,
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editMode && _vm.isEditable,
                                expression: "editMode&&isEditable",
                              },
                            ],
                            staticClass: "edit-buttons-panel",
                            style: _vm.buttonsPanelStyle,
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.allowMultipleAssign,
                                    expression: "allowMultipleAssign",
                                  },
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.duplicateTaskLabel,
                                    expression: "duplicateTaskLabel",
                                  },
                                ],
                                staticClass: "edit-buttons-panel__button copy",
                                on: { click: _vm.cloneForMultipleAssignee },
                              },
                              [_c("i", { staticClass: "fa fa-clone" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.allowDrag,
                                    expression: "allowDrag",
                                  },
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.reorderTasksLabel,
                                    expression: "reorderTasksLabel",
                                  },
                                ],
                                staticClass: "edit-buttons-panel__button drag",
                                on: {
                                  mousedown: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.dragGripClicked.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  touchstart: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.dragGripTouched.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-arrows-alt" })]
                            ),
                            _vm._v(" "),
                            _vm.allowDelete
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.deleteTaskLabel,
                                        expression: "deleteTaskLabel",
                                      },
                                    ],
                                    staticClass:
                                      "edit-buttons-panel__button delete",
                                    on: { click: _vm.deleteTask },
                                  },
                                  [_c("i", { staticClass: "fa fa-trash-o" })]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "goal-task-component__content__title",
                            class: { "big-title": _vm.bigTitle },
                          },
                          [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.isEditable && _vm.editMode
                                        ? _vm.editTaskLabel
                                        : undefined,
                                    expression:
                                      "(isEditable&&editMode)?editTaskLabel:undefined",
                                  },
                                ],
                                staticClass: "vertical-middle",
                                attrs: {
                                  href: _vm._f("backHrefQuery")(_vm.task.link),
                                },
                                on: { click: _vm.editOrOpenTask },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.task.name) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.editTaskLabel,
                                  expression: "editTaskLabel",
                                },
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editMode && _vm.isEditable,
                                  expression: "editMode&&isEditable",
                                },
                              ],
                              staticClass:
                                "fa vertical-middle fa-pencil pointer edit-title-pencil",
                              on: { click: _vm.editTask },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        (_vm.routerNavigation &&
                          !_vm.task.uses_default_avatar) ||
                        _vm.task.uses_generated_avatar
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm._f("backHrefQuery")(_vm.task.link),
                                },
                              },
                              [
                                _vm.isMobile
                                  ? _c("div", {
                                      staticClass:
                                        "goal-task-component__avatar-part__avatar",
                                      style: _vm.taskAvatarStyle,
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : !_vm.task.uses_default_avatar ||
                            _vm.task.uses_generated_avatar
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm._f("backHrefQuery")(_vm.task.link),
                                },
                              },
                              [
                                _vm.isMobile
                                  ? _c("div", {
                                      staticClass:
                                        "goal-task-component__avatar-part__avatar",
                                      style: _vm.taskAvatarStyle,
                                    })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("goal-task-time-assignee-panel", {
                          attrs: {
                            task: _vm.task,
                            "edit-mode": _vm.editMode,
                            "is-assignee-editable": _vm.isAssigneeEditable,
                            "is-editable": _vm.isEditable,
                            layout: _vm.layout,
                            "router-navigation": _vm.routerNavigation,
                          },
                          on: {
                            editDates: _vm.editDates,
                            editAssignee: _vm.editAssignee,
                          },
                        }),
                        _vm._v(" "),
                        _vm.task.has_steps
                          ? _c("goal-task-steps", {
                              class: {
                                "add-place-for-left-panel":
                                  _vm.isCompletedMarkEditable(_vm.task) ||
                                  _vm.syncIsPossible ||
                                  _vm.task.has_steps,
                                "add-place-for-avatar":
                                  !_vm.task.uses_default_avatar ||
                                  _vm.task.uses_generated_avatar,
                              },
                              attrs: {
                                "allow-set-assistance-options":
                                  _vm.allowSetAssistanceOptions(_vm.task),
                                steps: _vm.task.steps,
                                "task-id": _vm.task.id,
                                completable: _vm.isCompletedMarkEditable(
                                  _vm.task
                                ),
                                availableCompletionOptions:
                                  _vm.availableCompletionOptions,
                              },
                              on: {
                                taskCompleted: _vm.onTaskCompletedByStep,
                                taskInCompleted: _vm.onTaskInCompletedByStep,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.task.description,
                                expression: "task.description",
                              },
                            ],
                            staticClass:
                              "goal-task-component__content__description",
                          },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.taskDescription))]),
                            _vm._v(" "),
                            _vm.isDescriptionLargerThenPossible
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "goal-task-component__content__description__show-all",
                                    on: { click: _vm.showWholeDescription },
                                  },
                                  [_vm._v("show all")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.editMode && _vm.isEditable
                    ? _c(
                        "div",
                        { staticStyle: { margin: "5px 0" } },
                        [
                          _c("vue-tags-input", {
                            attrs: {
                              "max-tags": 20,
                              tags: _vm.tags,
                              maxlength: 100,
                              "autocomplete-items": _vm.autocompleteItems,
                            },
                            on: {
                              "tags-changed": _vm.tagsChanged,
                              "before-adding-tag": _vm.beforeAddingTag,
                              "before-deleting-tag": _vm.beforeDeletingTag,
                            },
                            model: {
                              value: _vm.tag,
                              callback: function ($$v) {
                                _vm.tag = $$v
                              },
                              expression: "tag",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticStyle: { margin: "5px 0" } },
                        [
                          _c("task-tags-list", {
                            attrs: { tags: _vm.task.tags },
                          }),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "children-container-el",
                      staticClass: "goal-task-component__children-container",
                      class: {
                        opened: !_vm.childrenCollapsed,
                        "goal-task-component__children-container_collapsing":
                          _vm.childrenCollapseChanging,
                      },
                    },
                    [
                      !_vm.childrenCollapsed && !_vm.withoutChildren
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "goal-task-component__children-container-inner",
                            },
                            _vm._l(_vm.visibleChildren, function (childTask) {
                              return _c("goal-task-child", {
                                key: childTask.id,
                                attrs: {
                                  task: childTask,
                                  "parent-task": _vm.task,
                                  goal: _vm.goal,
                                  layout: _vm.layout,
                                  "router-navigation": _vm.routerNavigation,
                                  "allow-set-assistance-options":
                                    _vm.allowSetAssistanceOptions,
                                  "edit-mode": _vm.editMode,
                                  "is-assignee-editable":
                                    _vm.isAssigneeEditable,
                                  "is-editable": _vm.isEditable,
                                  "is-completed-mark-editable":
                                    _vm.isCompletedMarkEditable,
                                  syncIsPossible: _vm.syncIsPossible,
                                  "is-synced":
                                    _vm.task.children &&
                                    _vm.task.children.length &&
                                    _vm.syncedChildrenIdList.includes(
                                      childTask.id
                                    ),
                                  "tags-in-project": _vm.tagsInProject,
                                  "event-params-for-tasks":
                                    _vm.eventParamsForTasks,
                                  "allow-link-to-event": _vm.allowLinkToEvent,
                                  availableCompletionOptions:
                                    _vm.availableCompletionOptions,
                                },
                                on: {
                                  toggleSync: _vm.toggleChildSync,
                                  childDeleted: _vm.onChildDeleted,
                                  completedStateUpdated:
                                    _vm.onCompletedStateUpdated,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.withoutChildren &&
                  _vm.visibleChildren &&
                  _vm.visibleChildren?.length
                    ? _c("goal-task-duplications-toggle", {
                        attrs: {
                          value: _vm.childrenCollapsed,
                          "duplications-count": _vm.visibleChildren.length,
                        },
                        on: { input: _vm.updateCollapsing },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }