<template>
    <transition name="list" :duration="enableAnimation?500:0">
        <div class="goal-task-component"
             :style="[taskBackgroundColor, dragStyle]"
             :class="{'under-drag':dragOverMe, 'goal-task-component_animated':enableAnimation}"
             v-observe-visibility="{
              callback: visibilityChanged,
              intersection: {
                rootMargin: '300px 0px 300px 0px',
              },
            }"
        >
            <div class="" v-if="isVisibleOnScreen">
                <div class="">
                    <div class="goal-task-component__content">
                        <div class="">
                            <div class="inline-block vertical-middle">
                                <i class="fa vertical-middle fa-sync pointer"
                                   style="font-size: 28px; margin-right: 5px"
                                   v-if="syncIsPossible"
                                   @click="toggleSync"
                                   :style="syncMarkStyle"
                                   v-tooltip="syncChangesLabel"
                                ></i>
                                <i class="fal fa-list-ol color-tag-gray vertical-middle" v-if="task.has_steps"
                                   style="font-size: 35px"
                                   :style="checkBoxStyle"></i>
                                <template v-else-if="isCompletedMarkEditable(task)">
                                    <goal-task-complete-dropdown
                                            v-if="allowSetAssistanceOptions(task)"
                                            :allow-incomplete="task.completed"
                                            :provided-assistance="task.provided_assistance"
                                            :available-completion-options="availableCompletionOptions"
                                            ref="complete-dropdown"
                                            @updated="onCompletionOptionsUpdated"
                                    >
                                        <i class="fa vertical-middle fa-check-square-o vertical-middle goal-task-component__complete-checkbox"
                                           v-tooltip="completedMarkHintText"
                                           ref="complete-checkbox"
                                           :style="checkBoxStyle"></i>
                                    </goal-task-complete-dropdown>
                                    <i class="fa vertical-middle fa-check-square-o vertical-middle goal-task-component__complete-checkbox"
                                       v-tooltip="completedMarkHintText"
                                       v-else
                                       tabindex="0"
                                       role="button"
                                       @keydown.enter="toggleCompletedStatus"
                                       @click="toggleCompletedStatus"
                                       ref="complete-checkbox"
                                       :style="checkBoxStyle"></i>
                                </template>
                            </div>
                            <div class="goal-task-component__status-mark" v-if="statusLabelVisible"
                                 :style="statusColor">
                                {{ statusLabel }}
                            </div>
                            <div class="goal-task-assignee-and-time__counters-part">
                                <div class="goal-task-assignee-and-time__comments-counter-container inline-block"
                                >
                                    <div>
                    <span class="fa-stack" style="vertical-align: top;">
                        <i class="fal fa-comment fa-stack-2x"></i>
                        <router-link class="fa-stack-1x" style="color: #333; text-decoration: none"
                                     :to="task.link |backHrefQuery"
                                     v-if="routerNavigation">{{ task.comments_count }}</router-link>
                        <a class="fa-stack-1x" style="color: #333; text-decoration: none"
                           :href="task.link |backHrefQuery" v-else>{{ task.comments_count }}</a>
                    </span>
                                    </div>
                                </div>
                                <div class="goal-task-assignee-and-time__attachments-counter inline-block"
                                     v-if="task.attachments_count">
                                    <router-link :to="task.link |backHrefQuery"
                                                 v-if="routerNavigation"
                                                 style="text-decoration:none"
                                    >
                                        <i class="fal fa-paperclip"></i><span>{{ task.attachments_count }}</span>
                                    </router-link>
                                    <a :href="task.link |backHrefQuery" v-else style="text-decoration:none">
                                        <i class="fal fa-paperclip"></i><span>{{ task.attachments_count }}</span>
                                    </a>
                                </div>

                                <div class="goal-task-assignee-and-time__additional-buttons inline-block">

                                </div>
                            </div>

                        </div>
                        <div class="goal-task-component__checkbox-and-avatar"
                             :class="{'no-avatar':task.uses_default_avatar&&!task.uses_generated_avatar}"
                             v-if="isDesktop">
                            <router-link :to="task.link |backHrefQuery"
                                         v-if="routerNavigation&&!task.uses_default_avatar||task.uses_generated_avatar">
                                <div class="goal-task-component__avatar-part__avatar" :style="taskAvatarStyle">
                                </div>
                            </router-link>
                            <a :href="task.link |backHrefQuery"
                               v-else-if="!task.uses_default_avatar||task.uses_generated_avatar">
                                <div class="goal-task-component__avatar-part__avatar" :style="taskAvatarStyle">
                                </div>
                            </a>
                        </div>
                        <div class="edit-buttons-panel" v-show="editMode&&isEditable" :style="buttonsPanelStyle">
                            <div class="edit-buttons-panel__button copy"
                                 v-show="allowMultipleAssign"
                                 v-tooltip="duplicateTaskLabel"
                                 @click="cloneForMultipleAssignee"
                            >
                                <i class="fa fa-clone"></i>
                            </div>
                            <div class="edit-buttons-panel__button drag"
                                 v-show="allowDrag"
                                 v-tooltip="reorderTasksLabel"
                                 @mousedown.stop.prevent="dragGripClicked"
                                 @touchstart.stop.prevent="dragGripTouched"
                                 @click.stop.prevent>
                                <i class="fa fa-arrows-alt"></i>
                            </div>
                            <div class="edit-buttons-panel__button delete" v-tooltip="deleteTaskLabel"
                                 @click="deleteTask"
                                 v-if="allowDelete">
                                <i class="fa fa-trash-o"></i>
                            </div>
                        </div>
                        <div class="goal-task-component__content__title" :class="{'big-title':bigTitle}">
                            <a :href="task.link |backHrefQuery"
                               class="vertical-middle"
                               @click="editOrOpenTask"
                               v-tooltip="(isEditable&&editMode)?editTaskLabel:undefined">{{ task.name }}
                            </a>
                            <i v-tooltip="editTaskLabel"
                               class="fa vertical-middle fa-pencil pointer edit-title-pencil"
                               v-show="editMode&&isEditable"
                               @click="editTask"></i>
                        </div>

                        <router-link :to="task.link |backHrefQuery"
                                     v-if="routerNavigation&&!task.uses_default_avatar||task.uses_generated_avatar">
                            <div class="goal-task-component__avatar-part__avatar" v-if="isMobile"
                                 :style="taskAvatarStyle">
                            </div>
                        </router-link>
                        <a :href="task.link |backHrefQuery"
                           v-else-if="!task.uses_default_avatar||task.uses_generated_avatar">
                            <div class="goal-task-component__avatar-part__avatar" v-if="isMobile"
                                 :style="taskAvatarStyle">
                            </div>
                        </a>

                        <goal-task-time-assignee-panel
                                :task="task"
                                :edit-mode="editMode"
                                :is-assignee-editable="isAssigneeEditable"
                                :is-editable="isEditable"
                                :layout="layout"
                                :router-navigation="routerNavigation"
                                @editDates="editDates"
                                @editAssignee="editAssignee"
                        >
                        </goal-task-time-assignee-panel>

                        <goal-task-steps v-if="task.has_steps"
                                         :allow-set-assistance-options="allowSetAssistanceOptions(task)"
                                         :steps="task.steps"
                                         :task-id="task.id"
                                         :completable="isCompletedMarkEditable(task)"
                                         :availableCompletionOptions="availableCompletionOptions"
                                         @taskCompleted="onTaskCompletedByStep"
                                         @taskInCompleted="onTaskInCompletedByStep"
                                         :class="{'add-place-for-left-panel': isCompletedMarkEditable(task)||syncIsPossible||task.has_steps, 'add-place-for-avatar':!task.uses_default_avatar||task.uses_generated_avatar}"
                        ></goal-task-steps>

                        <div class="goal-task-component__content__description" v-show="task.description">
                            <span>{{ taskDescription }}</span> <span v-if="isDescriptionLargerThenPossible"
                                                                     class="goal-task-component__content__description__show-all"
                                                                     @click="showWholeDescription">show all</span>
                        </div>

                    </div>
                </div>
                <div class="" v-if="editMode&&isEditable" style="margin: 5px 0">
                    <vue-tags-input
                            v-model="tag"
                            :max-tags="20"
                            :tags="tags"
                            :maxlength="100"
                            @tags-changed="tagsChanged"
                            :autocomplete-items="autocompleteItems"
                            @before-adding-tag="beforeAddingTag"
                            @before-deleting-tag="beforeDeletingTag"
                    />
                </div>
                <div v-else style="margin: 5px 0">
                    <task-tags-list :tags="task.tags"></task-tags-list>
                </div>
                <div class="goal-task-component__children-container"
                     ref="children-container-el"
                     :class="{opened:!childrenCollapsed, 'goal-task-component__children-container_collapsing':childrenCollapseChanging}">
                    <div class="goal-task-component__children-container-inner"
                         v-if="!childrenCollapsed&&!withoutChildren">
                        <goal-task-child v-for="childTask in visibleChildren"
                                         :task="childTask"
                                         :parent-task="task"
                                         :goal="goal"
                                         :layout="layout"

                                         :key="childTask.id"
                                         :router-navigation="routerNavigation"

                                         :allow-set-assistance-options="allowSetAssistanceOptions"
                                         :edit-mode="editMode"
                                         :is-assignee-editable="isAssigneeEditable"
                                         :is-editable="isEditable"
                                         :is-completed-mark-editable="isCompletedMarkEditable"
                                         :syncIsPossible="syncIsPossible"

                                         :is-synced="task.children&&task.children.length&&syncedChildrenIdList.includes(childTask.id)"
                                         @toggleSync="toggleChildSync"
                                         @childDeleted="onChildDeleted"

                                         :tags-in-project="tagsInProject"
                                         :event-params-for-tasks="eventParamsForTasks"

                                         :allow-link-to-event="allowLinkToEvent"
                                         :availableCompletionOptions="availableCompletionOptions"

                                         @completedStateUpdated="onCompletedStateUpdated"
                        ></goal-task-child>
                    </div>
                </div>
                <goal-task-duplications-toggle :value="childrenCollapsed" :duplications-count="visibleChildren.length"
                                               v-if="!withoutChildren&&visibleChildren&&visibleChildren?.length"
                                               @input="updateCollapsing"></goal-task-duplications-toggle>
            </div>
        </div>
    </transition>
</template>

<script>
    import Vue from 'vue';
    import { VueTagsInput } from '@johmun/vue-tags-input';
    import TaskTagsList from '../../projects/GoalDetailPage/TaskTagsList.vue';
    import GoalTaskTagsMixin from '../../projects/GoalDetailPage/goal_tasks/components/GoalTaskTagsMixin.js';
    import MouseEventsMixin from '../../../../vue/mixins/MouseEventsMixin.js';
    import GoalTaskDragAspectMixin
        from '../../projects/GoalDetailPage/goal_tasks/components/GoalTaskDragAspectMixin.js';
    import GoalTaskMixin from '../../projects/GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskMixin';
    import GoalTaskChild from '../../projects/GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskChild.vue';
    import GoalTaskDuplicationsToggle
        from '../../projects/GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskDuplicationsToggle.vue';
    import GoalTaskTimeAssigneePanel
        from '../../projects/GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskTimeAssigneePanel.vue';
    import GoalTaskCompleteDropdown
        from '../../projects/GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskCompleteDropdown.vue';
    import { VISUAL_DATE_FORMAT } from '../../events/new_calendar/config';

    import GoalTaskSteps from '../../projects/GoalDetailPage/goal_tasks/components/GoalTask/GoalTaskSteps.vue';
    import RouterNavigationMixin from '../../../../vue/mixins/RouterNavigationMixin';
    import { CurrentUserClass } from 'shared/CurrentUser';

    export default {
        props: {
            initialTask: {
                type: Object
            },
            hideCompletedChildren: {
                type: Boolean,
                default: false,
            },
            hideInCompletedChildren: {
                type: Boolean,
                default: false,
            },
            goal: Object,
            withoutChildren: {
                type: Boolean,
                default: false,
            },
            availableCompletionOptions: Array,
        },
        data() {
            return {
                task: this.initialTask,

                childrenCollapsed: true,
                childrenCollapseChanging: false,

                syncedChildrenIdList: [],
                isVisibleOnScreen: false,


            };
        },
        mixins: [GoalTaskTagsMixin, MouseEventsMixin, GoalTaskDragAspectMixin, GoalTaskMixin, RouterNavigationMixin],
        components: {
            VueTagsInput,
            TaskTagsList,
            GoalTaskChild,
            GoalTaskDuplicationsToggle,
            GoalTaskTimeAssigneePanel,
            GoalTaskCompleteDropdown,
            GoalTaskSteps,
        },
        computed: {
            editTaskLabel() {
                return `Edit ${this.$customTerm('Task')}`;
            },
            deleteTaskLabel() {
                return `Delete ${this.$customTerm('Task')}`;
            },
            syncChangesLabel() {
                return `Sync changes with cloned ${this.$customTerm('Tasks')}.`;
            },
            duplicateTaskLabel() {
                return `Duplicate ${this.$customTerm('Task')}`;
            },
            reorderTasksLabel() {
                return `Re-order ${this.$customTerm('Tasks')}`;
            },
            visibleChildren() {
                if (!this.task.children) {
                    return;
                }
                if (this.hideCompletedChildren) {
                    return this.task.children.filter(c => !c.completed);
                } else if (this.hideInCompletedChildren) {
                    return this.task.children.filter(c => c.completed);
                } else {
                    return this.task.children;
                }
            },
            bigTitle() {
                return this.task && this.task.name.length > 50;
            },
            syncIsPossible() {
                return this.isEditable && this.editMode && !!this.task.children && !!this.task.children.length;
            },
            isMobile() {
                return this.layout === 'mobile';
            },
            isDesktop() {
                return this.layout === 'desktop';
            },
            syncMarkStyle() {
                const style = { 'cursor': 'pointer' };
                if (!this.syncEnabled) {
                    style['color'] = '#9c9c9c';
                } else {
                    style['color'] = '#2a7bac';
                }
                return style;
            },
            syncEnabled() {
                return this.syncedChildrenIdList.length;
            }
        },
        methods: {
            updateCollapsing(v) {
                this.childrenCollapseChanging = true;
                if (!v) {
                    this.$nextTick(() => {
                        this.childrenCollapsed = v;
                    });
                } else {
                    this.childrenCollapsed = v;
                }
                setTimeout(() => {
                    this.childrenCollapseChanging = false;
                }, 700);
            },
            allowSetAssistanceOptions(task) {
                const CurrentUser = new CurrentUserClass();
                const goalOwner = this.goal.owner;
                const taskAssignee = task.assignee;
                /*
                so conditions stack: 1) assignee is SU 2) buddy is goal admin 3) buddy marks task completed. if all 3 are true - then show criteria menu - else just mark completed with status "signed off by X"
                 */
                if (CurrentUser.is_guardian_of(goalOwner.id)) {
                    return true;
                }
                if (taskAssignee && CurrentUser.is_guardian_of(taskAssignee.id)) {
                    return true;
                }
                return false;
            },

            toggleSync() {
                this.evaluateSyncedChildren(!this.syncEnabled);
            },
            toggleChildSync(task) {
                if (this.syncedChildrenIdList.includes(task.id)) {
                    this.syncedChildrenIdList.splice(this.syncedChildrenIdList.indexOf(task.id), 1);
                } else {
                    this.syncedChildrenIdList.push(task.id);
                }
            },
            evaluateSyncedChildren(v = this.editMode) {
                if (!this.isEditable) {
                    return;
                }
                if (!this.task.children) {
                    return;
                }
                if (v) {
                    this.syncedChildrenIdList = this.visibleChildren.map(t => t.id);
                } else {
                    this.syncedChildrenIdList = [];
                }
            },
            cloneForMultipleAssignee() {

                const goalTaskFactory = Vue.getAngularModule('goalTaskFactory');
                const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
                const notifications = Vue.getAngularModule('notifications');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const additionalFilters = [];
                additionalFilters.push({
                    name: 'members_type',
                    filter: true,
                    slot: 'left-from-search',
                    value: 'all',
                    options: [
                        {
                            value: 'all',
                            'name': 'All Users'
                        },
                        {
                            value: 'non_su',
                            'name': 'Non Supported Users'
                        },
                        {
                            value: 'su',
                            'name': 'Supported Users'
                        },
                        // {value: 'buddy', 'name': 'Buddies'},
                    ],
                    paramsWhenTrue: 'members_type'
                });

                if (this.eventParamsForTasks) {
                    const taskIsLinkedToEvent = this.task.link_info && this.task.link_info.date_linked;
                    additionalFilters.push({
                        name: 'members_only',
                        text: 'Show members of this Event only',
                        inputType: 'switch',
                        filter: true,
                        value: true,
                        paramsWhenTrue: {
                            from_event: this.eventParamsForTasks.eventId,
                            event_date: this.eventParamsForTasks.eventDateMoment.format(VISUAL_DATE_FORMAT),
                        }
                    });
                    if (taskIsLinkedToEvent) {
                        additionalFilters.push({
                            name: 'copy_link_for_assignee',
                            required: ['members_only'],
                            text: 'Add link to this Event on assignees Calendar',
                            inputType: 'switch',
                            filter: false,
                            value: true
                        });
                    }
                }

                goalTaskFactory.assignee_options(this.goal.id, {
                    header: `Duplicate and assign this ${this.$customTerm('Task')} to other users`,
                    info: ' ',
                    additional_filters: additionalFilters,
                }, true)
                    .then(([checked_id_list, unchecked_id_list, all_checked, filterParams]) => {
                        $rootScope.show_dimmer();
                        return GoalTaskResource.clone_for_multiple_assignees(this.task.id, checked_id_list, this.eventParamsForTasks ? {
                            from_event: this.eventParamsForTasks.eventId,
                            event_date: this.eventParamsForTasks.eventDateMoment.format(VISUAL_DATE_FORMAT),
                            copy_link_for_assignee: filterParams['copy_link_for_assignee'],
                            calendar_owner_id: this.eventParamsForTasks.calendarOwnerId,
                        } : {});
                    }, () => new Promise(() => {
                    }))
                    .then(resp => {
                        for (let task of resp.data.created_tasks) {
                            if (!this.task.children) {
                                Vue.set(this.task, 'children', []);
                            }
                            this.task.children.unshift(task);
                            this.syncedChildrenIdList.push(task.id);

                        }
                        if (resp.data.created_tasks.length === 1) {
                            notifications.success(`New ${this.$customTerm('Task')} was created`);
                        } else {
                            notifications.success(`${resp.data.created_tasks.length} new ${this.$customTerm('Tasks')} were created`);
                        }

                    }, err => notifications.error(err || 'Error'))
                    .finally($rootScope.hide_dimmer);
            },
            onChildDeleted(task) {
                this.task.children = this.task.children.filter(t => t.id !== task.id);
                const notifications = Vue.getAngularModule('notifications');
                notifications.success('Deleted');
            },
            onCompletedStateUpdated(task) {
                this.$emit('completedStateUpdated', task);
            },
            visibilityChanged(visibility) {
                this.isVisibleOnScreen = visibility;
            }
        },
        beforeMount() {
        },
        mounted() {
        },
        watch: {
            editMode(n) {
                this.evaluateSyncedChildren(n);
            },
        },
        name: 'GoalTaskDesktop'
    };
</script>

<style lang="scss" scoped>
  @import "../../styles/mixins";
  @import "../../styles/const";

  .goal-task-assignee-and-time {

    &__counters-part {
      display: inline-block;
      vertical-align: middle;
    }

    &__comments-counter-container {
      font-size: 17px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }

    &__additional-buttons {
      @media (max-width: 599px) {
        vertical-align: top;
        margin-top: 18px;

      }
    }

    &__attachments-counter {
      display: inline-block;
      font-size: 28px;
      line-height: 28px;
      vertical-align: middle;
    }
  }

  .goal-task-component {
    @include wide_item_container(170px);

    &__complete-checkbox {
      cursor: pointer;
      font-size: 35px;
    }

    &__status-mark {
      $size: 25px;
      vertical-align: middle;
      display: inline-block;
      min-height: $size;
      line-height: $size;
      font-size: $size/3*2;
      padding: 0 $size/2;
      border: 4px solid;
      border-radius: $size/3;
      font-weight: bold;
      text-align: center;
      margin: 0 10px;
      @media (min-width: 340px) {
        margin: 0 20px;
      }
    }

    .task-steps {
      @media (min-width: 600px) {
        /*margin-left: 124px;*/
        $avatar-size: 110px;
        $panel-size: 45px;
        &.add-place-for-left-panel {
          margin-left: $panel-size;
        }
        &.add-place-for-avatar {
          margin-left: $avatar-size;

          &.add-place-for-left-panel {
            margin-left: $avatar-size + $panel-size;
          }
        }
      }

    }

    &__children-container {
      max-height: 0;
      margin: 0 -5px;
      background-color: white;
      transition: max-height ease 0.7s;

      &_collapsing {
        overflow: hidden;
      }

      &.opened {
        max-height: 1000px;
      }
    }

    .edit-buttons-panel {
      float: right;
      display: inline-block;
      /*position: absolute;*/
      border-radius: 5px;
      right: 10px;
      top: 5px;
      height: 35px;
      padding: 5px;
      background-color: rgba(255, 255, 255, 0.5);
      text-align: center;

      &__button {
        font-size: 25px;
        line-height: 25px;
        display: inline-block;
        color: #9c9c9c;
        cursor: pointer;
        padding: 0 2px;

        &.delete {
          color: $red;
        }

        &.drag {
          cursor: grab;
        }

        &.copy {

        }

        &.assign {

        }
      }
    }

    .vue-tags-input ::v-deep {
      max-width: 100% !important;
      border-radius: 5px !important;

      .input {
        border-radius: 5px !important;
      }

      .tag {
        background-color: #9c9c9c;
        font-size: 16px;
        border-radius: 5px;
      }
    }

    &.under-drag {
      opacity: 0.5;
    }

    position: relative;
    transition: background-color ease 0.2s;
    padding: 5px;

    &__checkbox-and-avatar {
      float: left;
      width: 115px;
      height: 125px;

      &.no-avatar {
        width: 0;
        height: auto;
        margin-right: 0;
      }

      &.with-checkbox {
        &.no-avatar {
          width: 45px;
          margin-right: 0;
        }

        width: 150px;
      }

      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
    }

    &__checkbox-and-sync {
      width: 35px;
      text-align: center;
      display: inline-block;
      vertical-align: top;

      &__sync {
        &__container {
          height: 30px;
          font-size: 25px;
          line-height: 27px;

          .fa {
            transition: color 0.2s ease;
          }
        }
      }
    }

    &__avatar-part {
      &__avatar {
        @media (max-width: $help-panel-mobile-max) {
          display: inline-block;
          margin-top: 5px;
          margin-right: 5px;
        }
        @media (min-width: $help-panel-desktop-min) {
          margin-top: 7px;
        }
        display: inline-block;
        position: relative;
        width: 110px;
        height: 110px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &__content {
      width: 100%;
      margin-left: 5px;
      display: inline-block;
      vertical-align: top;

      &__link-switch {
        color: $gray;
        /*cursor: pointer;*/
        font-size: 20px;

        &.active {
          color: $blue;
        }
      }

      &__title {
        &.big-title {
          font-size: 16px;
          line-height: 16px;
        }

        a {
          text-decoration: none;
          color: #333;
        }

        font-weight: bolder;
        font-size: 20px;
        line-height: 20px;
        margin-top: 5px;
      }

      &__description {
        font-size: 16px;
        white-space: pre-wrap;

        &__show-all {
          cursor: pointer;
          font-weight: bolder;
        }
      }

      &__steps {
        font-size: 16px;
      }

    }


    .edit-title-pencil {
      font-size: 22px;
      line-height: 22px;
    }
  }

  .goal-task-component_animated.list-leave-active {
    transition: all 0.6s;
  }

  .goal-task-component_animated.list-leave-to /* .list-leave-active below version 2.1.8 */
  {
    opacity: 0;
    height: 0;
    transform: translateX(30px);
  }

</style>
