<template>
    <span>unhidden {{ 'Goal' | customTerm}}</span>
</template>

<script>

    export default {
        name: 'GoalUnhidden',
        props: {
            log: Object,
        }
    };
</script>

<style scoped lang="scss">

</style>
