<template>
    <span>assigned task <strong>#{{log.context.taskId}} {{log.context.taskName}}</strong> to <strong><simple-link :target="log.context_userprofile"></simple-link></strong></span>
</template>

<script>

    export default {
        name: 'GoalAttachmentAdded',
        props: {
            log: Object,
        }
    };
</script>

<style scoped lang="scss">

</style>
