<template>
    <span>updated {{ 'Task' | customTerm }}
        <strong>#{{ log.context.taskId }} {{ log.context.taskName }}</strong>.
        <span v-if="log.context.taskNewName">New name: <strong>{{ log.context.taskNewName}}</strong></span>
        <span v-if="log.context.taskNewDescription">New description: <i>{{ log.context.taskNewDescription}}</i></span>
        <span v-if="log.context.newAvatarId">New avatar: <strong>#{{ log.context.newAvatarId}}</strong></span>
    </span>
</template>

<script>
    export default {
        name: 'TaskUpdated',
        props: {
            log: Object,
        },
    };
</script>

<style scoped lang="scss">

</style>
