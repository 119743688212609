<template>
    <span class="counter-container">
        <span class="counter-label">{{ prefix }}</span>
                <transition
                        name="slide"
                        mode="out-in"
                >
                    <span :key="count" class="counter counter-label">{{ count }}</span>
                </transition>
        <span class="counter-label">{{ postfix }}</span>
    </span>

</template>

<script>
    export default {
        props: {
            count: {
                type: Number,
                default: 0,
            },
            prefix: {
                type: String,
                default: ''
            },
            postfix: {
                type: String,
                default: ''
            }
        },
        methods: {
            increment() {
                this.count++;
            }
        }
    };
</script>

<style scoped>
    .counter-container {
        font-size: 0;
    }

    .counter-label {
        font-size: 14px;
    }

    .counter {
        min-width: 16px;
        display: inline-block;
    }

    /* Slide old number down */
    .slide-leave-active {
        transition: transform 0.3s ease-in, opacity 0.3s ease-in;
    }

    .slide-leave-to {
        transform: translateY(30px);
        opacity: 0;
    }

    /* Slide new number from top */
    .slide-enter-active {
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    }

    .slide-enter {
        transform: translateY(-30px);
        opacity: 0;
    }
</style>
