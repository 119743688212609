<template>
    <span>updated {{ 'Goal' | customTerm }}
        <span v-if="log.context.goalNewName">New name: <strong>{{ log.context.goalNewName}}</strong>.</span>
        <span v-if="log.context.goalNewDescription">New description: <i>{{ log.context.goalNewDescription}}</i>.</span>
        <span v-if="log.context.hideTasks!==undefined">{{ 'Tasks' | customTerm }} are <i>{{ log.context.hideTasks ? 'Hidden' : 'Visible'}}</i>.</span>
        <span v-if="log.context.newAvatarId">New avatar: <strong>#{{ log.context.newAvatarId}}</strong>.</span>
        <span v-if="log.context.newAudioTitle">New Audio Title: <strong>#{{ log.context.newAudioTitle}}</strong>.</span>
        <span v-if="log.context.categoryName">New Category: <strong>#{{ log.context.categoryName}}</strong>.</span>
    </span>
</template>

<script>
    export default {
        name: 'GoalUpdated',
        props: {
            log: Object,
        },
    };
</script>

<style scoped lang="scss">

</style>
