<template>
    <div class="task-steps__task"
         :class="{completed:step.completed, small, 'not-achieved': isCompletedButNotAchieved}"
         :style="stepStyle"
    >
        <goal-task-complete-dropdown
                v-if="allowSetAssistanceOptions"
                :provided-assistance="step.provided_assistance"
                :allow-incomplete="step.completed"
                :available-completion-options="availableCompletionOptions"
                @updated="onCompletionOptionsUpdated"
        >
            <i ref="complete-checkbox-desktop"
               class="far fa-check-square task-steps__task__checkbox" :class="{pointer:completable}" :style="stepStyle">
            </i>
        </goal-task-complete-dropdown>
        <i v-else @click="onCompleteCheckboxClicked" ref="complete-checkbox-desktop"
           class="far fa-check-square task-steps__task__checkbox" :class="{pointer:completable}">
        </i>

        <span class="task-steps__task__name">{{ index ? index + '. ' : '' }}{{ step.name | cutName(450) }}</span>
    </div>
</template>

<script>
    import GoalTaskCompleteDropdown from './GoalTaskCompleteDropdown.vue';
    import TaskStepMixin from './TaskStepMixin';

    export default {
        components: { GoalTaskCompleteDropdown },
        mixins: [TaskStepMixin],
        props: {
            availableCompletionOptions: Array,
            small: {
                type: Boolean,
                default: false,
            },
            index: {
                type: Number,
                required: false,
            }
        },
        name: 'GoalTaskStepsStep.vue',
    };
</script>

<style scoped lang="scss">
  @import "../../../../../styles/const";

  .task-steps {
    &__task {
      $task-selector: &;

      &.completed {
        #{$task-selector}__name {
          text-decoration: line-through;
        }
      }

      font-size: 18px;
      color: $tag-gray;

      &__checkbox {
        font-size: 20px;
      }

      &.small {
        font-size: 16px;

        #{$task-selector}__checkbox {
          font-size: 18px;
        }
      }
    }

  }
</style>
