import Vue from 'vue';
import GoalTaskResource from '~/angular/app/projects/project_task_resource';

export default {
    methods:{
        markTaskAsCompleted(task, withAssistanceParams = false, providedAssistance = []) {
            Vue.loadingSpinner.show();
            this.enableAnimation = true;
            GoalTaskResource().mark_task_as_completed(task.id, withAssistanceParams, withAssistanceParams ? providedAssistance : undefined)
                .then(resp => {
                    this.task.status = resp.data.task.status;
                    this.task.completed = resp.data.task.completed;
                    this.task.completion_type = resp.data.task.completion_type;
                    this.task.completed_by = resp.data.task.completed_by;
                    this.task.completed_at = resp.data.task.completed_at;
                    this.task.provided_assistance = resp.data.task.provided_assistance;
                    Vue.notifications.success('Updated');
                    this.$emit('completedStateUpdated', task);
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(()=>{
                    Vue.loadingSpinner.hide();
                    this.enableAnimation = false;
                });
        },
        updateCompletionOptions(task, withSupport, providedAssistance = []) {
            Vue.loadingSpinner.show();
            GoalTaskResource().update_task_completion_params(task.id, withSupport, withSupport ? providedAssistance : undefined)
                .then(resp => {
                    this.task.status = resp.data.task.status;
                    this.task.completed = resp.data.task.completed;
                    this.task.completion_type = resp.data.task.completion_type;
                    this.task.completed_by = resp.data.task.completed_by;
                    this.task.completed_at = resp.data.task.completed_at;
                    Vue.set(this.task,'provided_assistance', resp.data.task.provided_assistance);
                    Vue.notifications.success('Updated');
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(Vue.loadingSpinner.hide);
        },
        markTaskAsIncompleted(task) {
            Vue.loadingSpinner.show();
            this.enableAnimation = true;
            GoalTaskResource().mark_task_as_incompleted(task.id)
                .then(resp => {
                    this.task.status = resp.data.task.status;
                    this.task.completed = resp.data.task.completed;
                    this.task.completion_type = resp.data.task.completion_type;
                    this.task.completed_by = resp.data.task.completed_by;
                    this.task.completed_at = resp.data.task.completed_at;
                    this.task.provided_assistance = [];
                    Vue.notifications.success('Updated');
                    this.$emit('completedStateUpdated', task);
                }, err => {
                    Vue.notifications.error(err || 'Error');
                })
                .finally(()=>{
                    Vue.loadingSpinner.hide();
                    this.enableAnimation = false;
                });
        },
        onCompletionOptionsUpdated({
            result,
            withSupport,
            options
        }) {
            if (result === 'complete') {
                if(this.task.completed) {
                    this.updateCompletionOptions(this.task, withSupport, options);
                }else{
                    this.markTaskAsCompleted(this.task, withSupport, options);
                }

            } else {
                this.markTaskAsIncompleted(this.task);
            }
        },
    }
}
