var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "access-forbidden-warning" }),
      _vm._v(" "),
      _vm.showMainPart
        ? _c("div", { staticClass: "main-task-content" }, [
            _c("div", { staticClass: "header-panel" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4",
                    staticStyle: { "padding-left": "22px" },
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-xs-12" }, [
                        _c("img", {
                          staticClass:
                            "visible-print header-panel__avatar-for-print",
                          attrs: {
                            src: _vm.task.avatar,
                            width: "90",
                            height: "90",
                            alt: "Task avatar",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "header-panel__avatar-container",
                          style: _vm.taskAvatarStyle,
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-8 text-right header-panel__simple-header-right-part hidden-print",
                  },
                  [
                    _vm.allowDelete
                      ? _c("folder-button-edit-toggle", {
                          attrs: { "edit-mode": _vm.editMode },
                          on: { toggleEditMode: (v) => (_vm.editMode = v) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isCurrentUserAuthenticated
                      ? _c("vue-folder-button", {
                          ref: "backButton",
                          attrs: {
                            target: _vm.backUrl,
                            text: "Back",
                            "aria-label": "Back",
                            role: "button",
                            color: "blue",
                            "icon-class": "fa-mail-reply",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-sm-12",
                    staticStyle: { "padding-left": "22px" },
                  },
                  [
                    _vm.taskEditable && _vm.editMode
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "header-panel__header_title hidden-print pointer text-underline",
                            attrs: { tabindex: "0", role: "button" },
                            on: {
                              click: _vm.editTask,
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.editTask.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.taskNameInHeader) + " "),
                            _c("i", { staticClass: "fa fa-pencil" }),
                          ]
                        )
                      : _c(
                          "span",
                          {
                            staticClass:
                              "header-panel__header_title hidden-print",
                          },
                          [_vm._v(_vm._s(_vm.taskNameInHeader))]
                        ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "header-panel__header_title visible-print",
                      },
                      [_vm._v(_vm._s(_vm.task.name))]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm._f("customTerm")("Task")) +
                          " created by\n                        "
                      ),
                      _c(
                        "strong",
                        [
                          _c("simple-link", {
                            attrs: {
                              target: _vm.task.created_by,
                              "tab-index": "-1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(
                        " on " +
                          _vm._s(_vm._f("gbdate")(_vm.task.created_at)) +
                          ".\n                    "
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _vm.task
                    ? _c("task-main-info", {
                        attrs: {
                          initialTask: _vm.task,
                          "goal-category": _vm.goalCategory,
                          "linked-events": _vm.linkedEvents,
                          "is-goal-admin": _vm.isAdmin,
                          availableCompletionOptions:
                            _vm.availableCompletionOptions,
                          "edit-mode": _vm.editMode,
                        },
                        on: {
                          updateDescription: _vm.editTask,
                          updateAssignee: _vm.editAssignee,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.singleAttachmentMode
                  ? _c(
                      "div",
                      {
                        staticClass: "single-attachment-container",
                        class: { pointer: _vm.attachmentCanBeShownInPopup },
                      },
                      [
                        _c("vue-inline-media", {
                          attrs: {
                            item: _vm.attachments[0].media_attachment,
                            "max-height": "300px",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return (() =>
                                _vm.attachmentCanBeShownInPopup
                                  ? _vm.showAttachment(
                                      _vm.attachments[0].media_attachment
                                    )
                                  : undefined).apply(null, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._l(_vm.attachments, function (attachment) {
                      return _c("folder-item", {
                        key: attachment.id,
                        attrs: {
                          attachment: attachment.media_attachment,
                          size: 90,
                          "allow-delete": _vm.canEditAttachments,
                        },
                        on: {
                          delete: function ($event) {
                            return _vm.del(attachment)
                          },
                          click: function ($event) {
                            return _vm.showAttachment(
                              attachment.media_attachment
                            )
                          },
                        },
                      })
                    }),
                _vm._v(" "),
                _vm.canEditAttachments && _vm.editMode
                  ? _c("div", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Add Attachment",
                          expression: "'Add Attachment'",
                        },
                      ],
                      staticClass: "hidden-print mm-icon add-attachment",
                      staticStyle: {
                        display: "inline-block",
                        "vertical-align": "middle",
                        width: "50px",
                        height: "50px",
                        cursor: "pointer",
                        "background-size": "contain",
                        "margin-left": "10px",
                        "margin-bottom": "10px",
                      },
                      attrs: {
                        "aria-label": "Add Attachment",
                        role: "button",
                        tabindex: "0",
                      },
                      on: {
                        click: _vm.addAttachment,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.addAttachment.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("comments-list-infinite-scroll", {
        attrs: {
          "target-id": _vm.taskId,
          "comments-resource": _vm.commentsResource,
          "allow-add": _vm.allowAdd,
          "allow-like": _vm.allowLike,
          "allow-delete": _vm.allowDelete && _vm.editMode,
          "allow-reply-expand": true,
          "allowed-media-types": ["video", "audio", "image", "document"],
          "initial-reply-to": _vm.initialReplyTo,
        },
      }),
      _vm._v(" "),
      _c("a", {
        attrs: { href: "#", "aria-hidden": "true" },
        on: { focus: _vm.focusToBackButton },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }