<template>
    <span>added a new {{ 'Task' | customTerm }}
        <strong>#{{ log.context.taskId }} {{ log.context.taskName }}</strong>
    </span>
</template>

<script>
    export default {
        name: 'TaskCreated',
        props: {
            log: Object,
        },
    };
</script>

<style scoped lang="scss">

</style>
