import { render, staticRenderFns } from "./GoalTaskPage.vue?vue&type=template&id=2ac2eff9&scoped=true&"
import script from "./GoalTaskPage.vue?vue&type=script&lang=js&"
export * from "./GoalTaskPage.vue?vue&type=script&lang=js&"
import style0 from "./GoalTaskPage.vue?vue&type=style&index=0&id=2ac2eff9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac2eff9",
  null
  
)

export default component.exports